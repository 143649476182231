import {
  THREE,
  game,
  cameraManager,
  errorManager,
  playersManager,
  PlayerSex,
  HairColorTypes
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig
} from '../../../config'
import { ModelsNames } from '../../../types'
import { Athlete } from '..'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }

    return game.getObject3D(meshAthleteName)

  }

  /**
   * Vytvorenie lyziara
   */
  public create(): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    this.prefixSex = playersManager.getPlayer().sex === PlayerSex.male ? '' : 'f_'

    super.create('Player')

    // spravime zmeny s vlasmi a klobukmi + ruky schovame, ak nahodou boli
    this.setHair()
    this.setHandsVisibility(false)

  }

  /**
   * Nastavenie vlasov a klobuku
   */
  private setHair(): void {

    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low`) as THREE.Mesh

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const maxCountHairTypes = 3
    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.setHairVisibility(true)

  }

  /**
   * Nastavenie viditelnosti pre vlasy a klobuk
   * @param visibility - Ci maju byt viditelne alebo nie
   */
  public setHairVisibility(visibility: boolean): void {

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return


    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hair.visible = hairTypesVisible.includes(this.hairType) && visibility
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType) && visibility

  }

  /**
   * Nastavenie viditelnosti samostanych ruk namiesto celeho tela
   * @param visible - Ci maju byt ruky viditelne
   */
  public setHandsVisibility(visible: boolean): void {

    const hands = this.athleteObject.getObjectByName('hands')
    if (hands) hands.visible = visible

    const body = this.athleteObject.getObjectByName(`${this.prefixSex}body_low`)
    if (body) body.visible = !visible

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   * @param staticMovement - true, ak sa nema dat lerp na poziciu kamery
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number,
    staticMovement?: boolean,
  ): void {

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp,
      staticMovement
    )

  }

  /**
   * nastavime camera settings podla game configu
   * @param lerpSize - volitelny iny lerp ako v game configu
   * @param staticMovement - true, ak pojde pozicia bez lerpu
   */
  public setGameCameraSettings(lerpSize = gameConfig.cameraConfig.changeLerp, staticMovement = false): void {

    this.changeCameraSettings(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      lerpSize,
      staticMovement
    )

  }

  /**
   * changes camera render settings
   * @param near - how close to camera stuff should be rendered
   * @param far - how far from camera stuff should be rendered
   * @param fov - field of view of camera
   */
  public changeCameraRenderSettings(near?: number, far?: number, fov?: number): void {

    cameraManager.changeRenderSettings(near, far, fov)

  }

}

export const player = new Player('')
