import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  playersManager,
  corePhasesManager,
  TutorialMessageColors,
  requestManager
} from '@powerplay/core-minigames'
import {
  Direction,
  PlayerStates,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '@/app/entities/athlete/player'
import { timeLimitManager } from '@/app/TimeLimitManager'
import { windConfig } from '@/app/config'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** ci je prvy pokus */
  public firstAttempt = true

  /** ci hrame s casom */
  public countdownActive = false

  /** ci hrame s vetrom */
  public windActive = false

  /** ci bola hlaska outoftime */
  public wasOutOfTime = false

  /** na kolky pokus sme splnili ciel */
  public attemptToHit30 = 0

  private activeEventType = TutorialEventType.awaitingEvent

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.shoot30 as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-1`
      },
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    windConfig.debug = {
      active: true,
      direction: Direction.E,
      value: 3
    }
    const tutorialSections = [
      {
        name: SectionNames.mobileSidePick,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('BlurState/SET_IS_ACTIVE', false)

        }
      },
      {
        name: SectionNames.introductionNewInstructor,
        id: 1,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSection,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          this.setObjectivesInit()
          disciplinePhasesManager.phaseStart.finishPhase()
          player.setState(PlayerStates.loading)
          this.resetTypeWrite()
          console.log('end start phase')

        }
      },
      {
        name: SectionNames.startSectionSecond,
        id: 3,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionThird,
        id: 4,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionFourth,
        id: 5,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()

        }
      },
      {
        name: SectionNames.startSectionFifth,
        id: 6,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

        }
      },
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    // uplne prvy tutorial
    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) {

      if (MobileDetector.isMobile()) return

      tutorialManager.setActualSectionId(1)
      return

    }

    tutorialManager.setActualSectionId(2)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType
    if (eventType === TutorialEventType.attemptWithTime) {

      if (this.wasOutOfTime) {

        this.activeEventType = TutorialEventType.awaitingEvent
        return

      }
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-2`)
      store.commit('TutorialState/SET_SHOW_TIME', true)

      tutorialUIChange.setAnne(true, false)

    }
    if (eventType === TutorialEventType.attemptWithWind) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-4`)
      store.commit('TutorialState/SET_SHOW_WIND', true)

      tutorialUIChange.setAnne(true, false)

    }
    if (eventType === TutorialEventType.attemptOutOfTime) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-3`)
      tutorialUIChange.setAnne(true, false)

    }
    if (eventType === TutorialEventType.finish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-5`, TutorialMessageColors.green)
      tutorialUIChange.setAnne(true, false)

    }

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (
      [
        TutorialEventType.attemptWithTime,
        TutorialEventType.attemptWithWind,
        TutorialEventType.finish,
      ].includes(this.activeEventType)
    ) {

      tutorialManager.nextSection()
      store.commit('TutorialState/SET_SHOW_TIME', false)
      store.commit('TutorialState/SET_SHOW_WIND', false)

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * nastavenia pri dalsom pokus
   */
  public nextAttempt(): void {

    if (!modes.isTutorial()) return

    if (this.firstAttempt) {

      this.firstAttempt = false
      return

    }


    if (this.countdownActive) {

      if (!this.windActive) {

        timeLimitManager.setActive(true)
        timeLimitManager.check()

        this.eventActionTrigger(TutorialEventType.attemptWithWind)


      }
      this.windActive = true

    }
    this.countdownActive = true

    if (!this.windActive && this.countdownActive) {

      timeLimitManager.setActive(true)
      timeLimitManager.check()

      this.eventActionTrigger(TutorialEventType.attemptWithTime)


    }

  }

  /**
   * nastavenia pri zacati aim fazy
   */
  public aimPhaseStared(): void {

    if (!modes.isTutorial()) return
    const text = MobileDetector.isMobile() ?
      'tutorialInfoBox2m' :
      'tutorialInfoBox2'

    store.commit('TutorialState/SET_INSTRUCTION_BOX_TEXT', text)

  }

  /**
   * Nastavenie veci pri zapichnuti sipu do terca
   */
  public arrowInTarget(): void {

    if (!modes.isTutorial()) return

    const playerScore = Number(playersManager.getStandings().find(standing => {

      return standing.playable

    })?.result) || 0

    if (playerScore >= 30) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.shoot30)
      this.attemptToHit30 = corePhasesManager.disciplineActualAttempt
      this.eventActionTrigger(TutorialEventType.finish)

    }
    store.commit('TutorialState/SET_SHOW_INSTRUCTION_BOX', false)

  }

  /**
   * Nastavenie pri vyprsani casu
   */
  public outOfTime(): void {

    if (!modes.isTutorial()) return
    this.wasOutOfTime = true
    this.eventActionTrigger(TutorialEventType.attemptOutOfTime)
    console.log('out of time')
    this.countdownActive = false

  }

}

export const tutorialFlow = new TutorialFlow()
