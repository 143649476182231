import {
  HairColorTypes,
  THREE
} from '@powerplay/core-minigames'
import { MinigameVersionTypes } from '../types'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** typ verzie minihry - POZOR, v inite a specialData moze tuto hondotu prepisat */
  minigameVersionType: MinigameVersionTypes.a,

  /** Pocet pokusov */
  numberOfAttempts: 6,

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(-0.543, 0.034 + 1.6, -2.25),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  cameraEmotionConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(-2, 1.05, 0.6),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(10, 2 + 0.3, -2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  /**
   * startovacia pozicia a rotacia
   */
  startPosition: new THREE.Vector3(0.83, 0.05, 66.59),
  startRotation: new THREE.Vector3(0, 0, 0),

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   */
  skipToFinish: {

    active: false,
    percent: 0.85

  },

  /** fix pozicie y pre hraca, aby nebol vo vzduchu */
  yPlayerCorrection: 0,

  /** Lerp pre quaternion kamery pocas behu */
  cameraQuaternionLerp: 0.15,

  /** Vysvietenie zasahu do terca */
  targetMarkers: {

    /** Farba vysvietenia */
    color: 'rgb(0, 255, 51)',

    tween: {

      delay: 0.25,

      duration: 0.7

    }

  },

  /** Farby vlasov */
  hairColors: {
    [HairColorTypes.blonde]: '#fcd681',
    [HairColorTypes.red]: '#ba2c13',
    [HairColorTypes.orange]: '#f56e07',
    [HairColorTypes.brown]: '#6b3e24',
    [HairColorTypes.black]: '#303233',
    [HairColorTypes.purple]: '#7146bd',
    [HairColorTypes.green]: '#61d64d',
    [HairColorTypes.blue]: '#3bdced',
    [HairColorTypes.pink]: '#ff5eb7',
  },

  /** Ako rychlo sa hybu oblaky - rotacia v radianoch */
  skyboxCloudsOffsetStep: 0.0003,

}
