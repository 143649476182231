import store from '@/store'
import {
  PlayerStates,
  type DisciplinePhaseManager,
  MinigameVersionTypes
} from '../../types'
import {
  drawConfig,
  gameConfig,
  trainingConfig
} from '@/app/config'
import {
  MobileDetector,
  gsap,
  modes,
  corePhasesManager
} from '@powerplay/core-minigames'
import { player } from '@/app/entities/athlete/player'
import { timeLimitManager } from '@/app/TimeLimitManager'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { wind } from '@/app/entities/athlete/Wind'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

/**
 * Trieda fazy pre natiahnutie - s barom na natiahnutie
 */
export class DrawPhaseWithBar implements DisciplinePhaseManager {

  /** Kvalita inputu */
  public quality = 0

  /** Pocitadlo frameov */
  private frameCounter = 0

  /** Ci uz odstartoval inputom */
  private started = false

  /** Ci je aktivny hlavny input pre natiahnutie */
  private drawInputActive = false

  /** Aktualna pozicia ukazovatela na bare */
  private barMarkPosition = 0

  /** Ci uz skoncila tato faza */
  private ended = false

  /** podmienky pre coundown a wind */
  private conditions = {
    countdownActive: true,
    windActive: true,
    windChanging: true,
    showWind: true
  }

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('draw phase started')
    if (modes.isTutorial()) {

      this.conditions = {
        countdownActive: tutorialFlow.countdownActive,
        windActive: tutorialFlow.windActive,
        windChanging: true,
        showWind: tutorialFlow.windActive
      }

    }
    if (modes.isTrainingMode()) {

      this.conditions = {
        countdownActive: true,
        windActive: trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].active ?? true,
        windChanging: trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].changing ?? true,
        showWind: true
      }

    }

    wind.isActive = this.conditions.windActive
    wind.isChanging = this.conditions.windChanging

  }

  /**
   * Zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    store.commit('ActionButtonState/SET_SHOW_JOYSTICK', true)
    startPhaseStateManager.enableInputs(true)
    const joystickDisabled = gameConfig.minigameVersionType === MinigameVersionTypes.b
    store.commit('InputsState/SET_DISABLED', joystickDisabled)

  }

  /**
   * Kontrola a riesenie inputov
   * @param pressStart - True, ak bol input aktivovany
   * @param isTouch - ci input je touch na mobile
   */
  public handleInputs(pressStart: boolean, isTouch = false): void {

    if (MobileDetector.isMobile() && !isTouch) return
    // aby neboli inputy hned po intre
    if (this.frameCounter < this.waitingFrames()) return

    if (pressStart && !this.started) {

      this.started = true
      this.drawInputActive = true

      // nastartujeme bar
      store.commit('DrawPhaseState/SET_ACTIVE', true)

    }

    if (!pressStart && this.drawInputActive) {

      // vyhodnotime bar a ukoncime natahovanie
      this.drawInputActive = false
      this.finishPhase(false)

    }

    // startPhaseStateManager.hideTextMessage()

  }

  /**
   * Vratenie percent fazy, aby sme vedeli, kde v animacii akurat mame byt
   * @returns Percento fazy
   */
  public getPhasePercent(): number {

    if (this.ended) return 1

    let percent = this.barMarkPosition / drawConfig.barType.barMaxValue
    if (percent > 1) percent = 1
    return percent

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    if (this.ended) return

    if (this.drawInputActive) {

      const { addValuePerFrame, barMaxValue } = drawConfig.barType

      // posuvame bar
      this.barMarkPosition += addValuePerFrame
      if (this.barMarkPosition > barMaxValue) {

        this.barMarkPosition = barMaxValue
        this.finishPhase(false)

      }
      console.log('aka je pozicia?', this.barMarkPosition)
      store.commit('DrawPhaseState/SET_MARK_POSITION', this.barMarkPosition)

    }

    this.frameCounter += 1

    if (this.frameCounter === this.waitingFrames()) {

      tutorialFlow.nextAttempt()

      timeLimitManager.setActive(this.conditions.countdownActive)
      player.setState(PlayerStates.drawing)
      this.showMobileButtons()

      store.commit(
        'WindState/SET_SHOW_WIND',
        this.conditions.showWind
      )
      store.commit(
        'WindState/SET_SHOW_COUNTDOWN',
        this.conditions.countdownActive
      )

    }

  }

  /**
   * Vypocet poctu freeze fames pred povolenim natiahnutia
   */
  private waitingFrames() {

    return !disciplinePhasesManager.isFirstAttemptInGroup() ?
      drawConfig.freezedFramesAfterStartNoReload :
      drawConfig.freezedFramesAfterStart

  }


  /**
   * Nastavenie kvality podla kliku
   */
  private setQuality(): void {

    const { barIdealValue, barMaxValue } = drawConfig.barType

    this.quality = barMaxValue - (5 * Math.abs(this.barMarkPosition - barIdealValue))
    if (this.quality < 0) this.quality = 0
    if (this.quality > barMaxValue) this.quality = barMaxValue

    console.log(`Kvalita draw fazy je: ${ this.quality}`)

  }

  /**
   * Ukoncene fazy
   * @param forced - True, ak sa vynutil koniec a preskakuje sa faza
   */
  public finishPhase = (forced: boolean): void => {

    if (this.ended) return

    store.commit(
      'WindState/SET_SHOW_WIND_CENTER',
      this.conditions.showWind
    )
    this.ended = true
    console.warn('draw phase ended')
    gsap.to({}, {
      onComplete: () => {

        store.commit('DrawPhaseState/SET_ACTIVE', false)

      },
      duration: 2
    })

    // musime preskakovat fazu
    if (forced) {

      this.quality = 0
      return

    }

    // nastavime kvalitu
    this.setQuality()

    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.quality = 0
    this.frameCounter = 0
    this.started = false
    this.drawInputActive = false
    this.barMarkPosition = 0
    this.ended = false
    this.conditions = {
      countdownActive: true,
      windActive: true,
      windChanging: true,
      showWind: true
    }

  }

}
