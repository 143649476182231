import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import {
  BowAnimationsNames,
  PlayerAnimationsNames
} from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.prepare1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.idle]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.idle1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.idle2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.loading]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.drawing]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.shoot]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy2]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy3]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.sad]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.afterEmotion]: {
    loop: true,
    starting: false
  }

}

/**
 * Konfig pre animacie luku
 */
export const animationsConfigBow: AnimationsSettingRepository = {

  [BowAnimationsNames.idle]: {
    loop: true,
    starting: true
  },
  [BowAnimationsNames.drawing]: {
    loop: false,
    starting: false
  },
  [BowAnimationsNames.shoot]: {
    loop: false,
    starting: false
  }

}
