import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(1.1436511278152466, 1.0832312107086182, 72.4385070800781),
      endPosition: new THREE.Vector3(1.1436511278152466, 1.0832312107086182, 72.4385070800781),
      startRotation: new THREE.Vector3(1.605138504069091, -0.0019179612356946384, -0.05588982909064788),
      endRotation: new THREE.Vector3(1.605138504069091, -0.0019179612356946384, -0.05588982909064788),
      duration: 3,
      notSkippableFrames: 20,
      fov: 41.9,
      offsetCamera: true
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(20.672060012817383, 5.88576889038086, 117.11141204833984),
      endPosition: new THREE.Vector3(24.31209945678711, 8.488192558288574, 99.2518310546875),
      startRotation: new THREE.Vector3(1.709798533283256, 0.02823006889741472, 0.19909446116305493),
      endRotation: new THREE.Vector3(1.709798533283256, 0.02823006889741472, 0.19909446116305493),
      fov: 70.7,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(1.1436511278152466, 1.0832312107086182, 72.4385070800781),
      endPosition: new THREE.Vector3(1.001293659210205, 1.3011038303375244, 69.93468475341797),
      startRotation: new THREE.Vector3(1.605138504069091, -0.0019179612356946384, -0.05588982909064788),
      endRotation: new THREE.Vector3(1.5711614124806637, -0.00001526660119359108, -0.05331056895874125),
      duration: 3,
      fov: 41.9,
      fovEnd: 53.9
    },
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.static]: undefined
  },
  trainingTask: [
    0,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    99,
    100
  ],
  wind: [
    {
      value: 0,
      changing: false,
      active: false
    },
    {
      value: 2,
      changing: false,
      active: true
    },
    {
      value: 4,
      changing: true,
      active: true
    },
    {
      value: 6,
      changing: true,
      active: true
    },
    {
      value: 8,
      changing: true,
      active: true
    },
    {
      value: 10,
      changing: true,
      active: true
    },
  ]


}
