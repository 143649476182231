<template>
  <div
    id="app"
    class="app"
  >
    <div style="z-index: 1000000">
      <div
        v-show="!modalActive && !trainingState.firstTutorialMessage"
      >
        <countdown-component />
        <hud-component v-show="debugState.isHudActive" />
        <error-modal
          class="pointer-events-auto"
          @close="closeApp"
        />
        <emotion-messages />
        <phase-start />
        <info-component />
        <draw-bar />
        <release-bar />
        <mobile-inputs
          v-if="isMobile"
        />
        <training-layout v-show="uiState.showTrainingLayout" />
        <training-results v-if="trainingResultsState.showResults" />
        <data-table />
        <info-keys v-if="!isMobile" />
        <start-stats-box />
        <text-message
          v-if="textMessageState.showMessage"
          :style="textMessageStyle"
          :first-line-text="textMessageState.firstLineText"
          :first-line-sprite-type="textMessageState.firstLineTextType"
          :second-line-text-type="textMessageState.secondLineTextType"
          :second-line-left-number="textMessageState.secondLineLeftNumber"
          :show-first-line="textMessageState.showFirstLine"
          :show-second-line="textMessageState.showSecondLine"
          :with-animation="true"
          :show-type="textMessageState.showType"
          :delay-to-hide="0.3"
          :duration-hide="[0.5, 0.5, 0.5, 0.5]"
          :duration-translation="[0.5, 0.5, 0.5 * 2, 0.5]"
          @finished-first-line="finishedFirstLine"
          @finished-second-line="finishedSecondLine"
        />
        <mini-table
          v-if="miniTableData.show"
          style="position:absolute; left:1%; top: 1%; margin: 0.25em 0px;"
          :rows-data="miniTableData.rowsData"
        />
        <black-overlay />
      </div>
      <fps-component />
      <tutorial-logic-component v-show="!modalActive" />
      <menu-section
        v-if="showMenu && !trainingState.firstTutorialMessage"
        :is-mobile="isMobile"
        style="pointer-events: auto"
        :is-app-ssm="isAppSSM2023"
        :show-overlay="modalActive"
        @toggle-ui="toggleUi"
      />
      <color-overlay-component
        v-if="isBlurActive && !blurState.isToggle"
        :opacity="0.6"
      />
      <loading-circle-overlay />
      <training-override
        style="pointer-events: auto"
        @mousedown.stop=""
        @mouseup.stop=""
        @touchstart.stop=""
        @touchend.stop=""
      />
    </div>
    <loading />
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'

// Internal Package codes
import {
  game,
  MobileDetector,
  requestManager,
  modes,
  inputsManager
} from '@powerplay/core-minigames'
import {
  ErrorModal,
  LoadingCircleOverlay,
  ColorOverlayComponent,
  TextMessage,
  MiniTable,
} from '@powerplay/core-minigames-ui-ssm'

// Component codes

// Debug Components
import HudComponent from '@/components/DebugComponents/HUD.vue'
// import DirectionsInfo from '@/components/DebugComponents/DirectionsInfo.vue'

// Game Components
import MobileInputs from '@/components/Inputs/MobileInputs.vue'
import PhaseStart from '@/components/PhaseStart.vue'
import DataTable from '@/components/Table/DataTable.vue'
import Loading from '@/components/Loading.vue'
import InfoComponent from '@/components/InfoComponent.vue'
import MenuSection from '@/components/MenuSection/index.vue'
import FpsComponent from '@/components/FpsComponent.vue'
import TrainingLayout from '@/components/Training/TrainingLayout.vue'
import TrainingResults from '@/components/Training/TrainingResults.vue'
import TutorialLogicComponent from '@/components/Tutorial/TutorialLogicComponent.vue'
import CountdownComponent from '@/components/CountdownComponent/index.vue'
import InfoKeys from '@/components/InfoKeys.vue'
import TrainingOverride from '@/components/Training/TrainingOverride.vue'
import DrawBar from '@/components/DrawBar.vue'
import ReleaseBar from '@/components/ReleaseBar.vue'
import StartStatsBox from '@/components/StartStatsBox.vue'
import BlackOverlay from '@/components/BlackOverlay.vue'

// Internal Codes
import { initiateListeners } from '@/helpers/initiateListeners'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { endManager } from '@/app/EndManager'

import { defineComponent } from 'vue'
import EmotionMessages from '../EmotionMessages.vue'
import { startPhaseStateManager } from '@/app/phases/StartPhase/StartPhaseStateManager'

export default defineComponent({
  name: 'StructureComponent',
  components: {
    HudComponent,
    // DirectionsInfo,
    Loading,
    MobileInputs,
    PhaseStart,
    MenuSection,
    ErrorModal,
    DataTable,
    InfoComponent,
    FpsComponent,
    TrainingLayout,
    TrainingResults,
    TutorialLogicComponent,
    CountdownComponent,
    LoadingCircleOverlay,
    InfoKeys,
    TrainingOverride,
    ColorOverlayComponent,
    DrawBar,
    ReleaseBar,
    StartStatsBox,
    TextMessage,
    MiniTable,
    BlackOverlay,
    EmotionMessages
  },
  data() {

    return {
      modalActive: false,
      isAppSSM2023: false
    }

  },
  computed: {
    ...mapGetters({
      uiState: 'UiState/getUiState',
      debugState: 'DebugState/getDebugState',
      showTrainingResults: 'TrainingResultsState/getTrainingResultsShow',
      trainingState: 'TrainingState/getTrainingState',
      isBlurActive: 'BlurState/getIsActive',
      blurState: 'BlurState/getBlurState',
      trainingResultsState: 'TrainingResultsState/getTrainingResultsState',
      textMessageState: 'TextMessageState/getTextMessageState',
      miniTableData: 'MiniTableState/getMiniTableData'
    }),
    isMobile(): boolean {

      return MobileDetector.isMobile()

    },
    isTutorial(): boolean {

      return modes.isTutorial()

    },
    showMenu(): boolean {

      return !this.showLoading() && !this.showTrainingResults

    },
    textMessageStyle(): unknown {

      return {
        position: 'absolute',
        left: '50%',
        top: '20%',
        'transform-origin': 'top'
      }

    },
  },
  watch: {
    isBlurActive(newState) {

      const blurClass = modes.isTutorial() ? 'blur-class-v2' : 'blur-class'

      if (newState) {

        document.getElementsByTagName('canvas')[0].classList.add(blurClass)

      } else {

        document.getElementsByTagName('canvas')[0].classList.remove(blurClass)

      }

      // osetrenie vypnutia menu pocas zobrazenej tabulky
      if (!newState && this.blurState.isTable) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)

      }

    }
  },
  created(): void {

    this.$store.commit('LoadingState/SET_STATE', {
      showLoading: true,
      loadingProgress: 0
    })

    this.isAppSSM2023 = modes.isAppWSM2021()
    initiateListeners(this)
    tutorialUIChange.registerVM(this)

  },
  methods: {
    showLoading(): void {

      return this.$store.getters['LoadingState/getLoadingState'].showLoading

    },
    closeApp(): void {

      endManager.prematureEnded = true
      this.$store.commit('LoadingCircleState/SET_STATE', {
        isActive: true
      })
      requestManager.redirect(true)

    },
    toggleUi(toggle = true) {

      if (toggle) {

        this.$store.commit('BlurState/SET_IS_ACTIVE', true)
        this.$store.commit('BlurState/SET_IS_TOGGLE', true)
        inputsManager.actionInputsBlocked = true
        this.modalActive = true
        game.pauseGame()

      } else {

        inputsManager.actionInputsBlocked = false
        this.modalActive = false
        this.$store.commit('BlurState/SET_IS_ACTIVE', false)
        this.$store.commit('BlurState/SET_IS_TOGGLE', false)
        game.resumeGame()

      }

    },
    finishedFirstLine() {

      startPhaseStateManager.hideTextMessage(1)

    },
    finishedSecondLine() {

      startPhaseStateManager.hideTextMessage(2)

    }
  }
})
</script>

<style>
.blur-class {
  filter: blur(4px);
}
.blur-class-v2 {
  filter: blur(8px);
}
</style>
<style lang="less" scoped>
.app {
  min-height: 100%;
  min-width: 100%;
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.absolute {
  position: absolute;
}

.midder {
  top: 50%;
  left: 50%;
}
</style>
