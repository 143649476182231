import type { Module } from 'vuex'
import type { RootState } from '../index'
import type { MiniTableRowData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/MiniTable/MiniTable.vue'


export interface MiniTableState {
  rowsData: MiniTableRowData[],
  show: boolean
}

const initialState = () => ({
  rowsData: [],
  show: false
})

const miniTableState: Module<MiniTableState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMiniTableData: (moduleState: MiniTableState) => moduleState
  },

  mutations: {
    RESET: (moduleState: MiniTableState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: MiniTableState, newState: MiniTableState) => {

      moduleState.rowsData = newState.rowsData
      moduleState.show = newState.show

    },
    ADD_ROW: (moduleState: MiniTableState, rowData: MiniTableRowData) => {

      moduleState.rowsData.push(rowData)

    },
    SET_SHOW: (moduleState: MiniTableState, show: boolean) => {

      moduleState.show = show

    }
  }
}

export default miniTableState
