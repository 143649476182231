import store from '@/store'
import {
  THREE,
  MobileDetector,
  game
} from '@powerplay/core-minigames'
import { Direction } from '@/app/types'
import { aimConfig } from '@/app/config'
import { wind } from '@/app/entities/athlete/Wind'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import type { DrawPhaseWithBar } from '../DrawPhase/DrawPhaseWithBar'

/**
 * manazer vychylovania pri strelbe
 */
export class AimingDirectionManager {

  /** aiming target point obj name */
  private readonly AIMING_TARGET_POINT = 'aiming_target_point'

  /** target point obj prefix */
  private readonly TARGET_POINT = 'target_point'

  /** kolko smerov mame */
  private CHANGE_DIRECTION_COUNT = 8

  /** nahodne vygenerovany smer */
  private actualDirection = this.getRandomDirection()

  /** posledna pozicia mysy */
  private lastMousePosition = new THREE.Vector2()

  /** aktualna pozicia mysy */
  public actualMousePosition = new THREE.Vector2()

  /** o kolko posuvame terc podla mysy */
  public mouseStep = new THREE.Vector2()

  /** pocitadlo framov pre zmenu smeru pri vychylovani */
  private changeFramesCount = 0

  /** miesto kam mierime */
  public aimingPoint = new THREE.Mesh()

  /** miesto na terci kam by sa malo vystrelit */
  public targetPoint = new THREE.Mesh()

  /** miesto na terci kam by sa malo vystrelit, keby nebolo vetra */
  public targetPointOriginal = new THREE.Mesh()

  /** odchylka */
  public deviation = 0

  /** smer odchylky */
  public direction = Direction.N

  /** velkost kroku v kazdom smere */
  public directionStep: Record<number, THREE.Vector2> = {}

  /** Limity na hybanie sa */
  private limitsToMove = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0
  }

  /** min step vychylovania */
  private minStepAuto = 0

  /** max step vychylovania */
  private maxStepAuto = 0

  /** ci sme urobili init shift */
  private isInitShiftDone = false

  /** Percenta pre shake */
  private shakePercent = 0

  /** Aktualny frame pre zotrvacnost */
  private frameLag = 0

  /** Posledny nastaveny offset pre pohyb */
  private lastMoveOffset = new THREE.Vector2()

  /** Offset pre pohyb pre potreby pocitania zotrvacnosti */
  private lagOffsetOriginal = new THREE.Vector2()

  /** Aktualna hodnota zotrvacnosti */
  private actualLag = new THREE.Vector2()

  /** Ci bol pri aplikovani zotrvacnosti */
  private movingInLag = false

  /** Raycast */
  private raycast = new THREE.Raycaster()

  /** Pomocny vektor pre diff */
  private helperVectorDiff = new THREE.Vector2

  /** Vector pre raycast smerom na terc */
  private readonly RAYCAST_DIR_VECTOR = new THREE.Vector3(0, 0, 1)

  /**
   * Inicializacia
   */
  public init(): void {

    this.createDirectionSteps()
    this.setLimitsToMove()

  }

  /**
   * Nastavenie limitov na pohyb
   */
  public setLimitsToMove(): void {

    const middlePosition = aimConfig.targetCenterPosition

    this.limitsToMove.minX = middlePosition.x - aimConfig.movementRange.x
    this.limitsToMove.maxX = middlePosition.x + aimConfig.movementRange.x
    this.limitsToMove.minY = middlePosition.y - aimConfig.movementRange.y
    this.limitsToMove.maxY = middlePosition.y + aimConfig.movementRange.y

  }

  /**
   * ziskame nahodny smer
   * @returns nahodne cislo podla poctu smerov
   */
  private getRandomDirection(): number {

    return Math.floor(Math.random() * this.CHANGE_DIRECTION_COUNT)

  }

  /**
   * Vypocitanie odchylky
   * @param release - True, ak ide o release, false ak ide o draw
   */
  public calculateDeviation(release = false): void {

    if (!aimConfig.deviation.active) return

    this.direction = THREE.MathUtils.randInt(0, 23) // lebo mame 24 smerov

    const quality = 0
    if (release) {

      this.deviation = aimConfig.deviation.max - aimConfig.deviation.coef * disciplinePhasesManager.phaseAim.quality

    } else {

      const qualityDraw = (disciplinePhasesManager.phaseDraw as DrawPhaseWithBar).quality
      this.deviation = aimConfig.deviation.max - aimConfig.deviation.coef * qualityDraw

    }

    const type = release ? 'release' : 'draw'
    console.log(
      `ODCHYLKA: ${this.deviation}, smer ${this.direction}, kvalita ${quality}`,
      `typ ${type}`
    )



  }

  /**
   * Nastavenie percent pre shake
   * @param percent - % pre shake
   */
  public setShakePercent(percent: number): void {

    this.shakePercent = percent

  }

  /**
   * Aktualizovanie
   */
  public update(): void {

    this.updateAimingPosition()

    // ak nemame zapnute vychylovanie tak koncime
    if (!aimConfig.shake.active) return
    this.changeActualChangeDirection()
    this.changeAimingPosition()

  }

  /**
   * zmenime smer vychylovania
   */
  private changeActualChangeDirection(): void {

    this.changeFramesCount++
    if (this.changeFramesCount % aimConfig.shake.directionFrames) return

    const chance = Math.random()

    if (chance <= aimConfig.shake.chanceSameDirection) return // nemenime smer

    // toto zahrna aj momentalny smer, mozno bude treba upravit
    this.actualDirection = this.getRandomDirection()

  }

  /**
   * zmenime poziciu aiming pointu podla pravidiel vychylovania
   */
  public changeAimingPosition(): void {

    // ked je percento 0, tak este nie je vychylovanie
    if (this.shakePercent === 0) return

    // spravime zaklad normalizovany na 1
    const change = this.directionStep[this.actualDirection].clone()

    // base hodnota
    const baseChangeValue = this.minStepAuto +
            (this.shakePercent * (this.maxStepAuto - this.minStepAuto))

    // konecne prenasobenie
    change.x *= (baseChangeValue * aimConfig.shake.coef)
    change.y *= (baseChangeValue * aimConfig.shake.coef)

    this.moveAimingPoint(change.x, change.y)

  }

  /**
   * vytvorime objekt v ktorom si zaznacime zakladne velkosti vychylovania
   */
  private createDirectionSteps(): void {

    // prejdeme si vsetky smery a nastavime direction step
    Object.keys(Direction).filter((v) => isNaN(Number(v))).forEach((_key, index) => {

      const radians = THREE.MathUtils.degToRad(index * 15)
      this.directionStep[index] = new THREE.Vector2(Math.sin(radians), Math.cos(radians))

    })

    const { minStep, maxStep, minStepMobile, maxStepMobile } = aimConfig.shake

    if (MobileDetector.isMobile()) {

      this.minStepAuto = minStepMobile
      this.maxStepAuto = maxStepMobile

    } else {

      this.minStepAuto = minStep
      this.maxStepAuto = maxStep

    }

  }

  /**
   * Nastavenie aktualnej zotrvacnosti
   * @param percent - Aktuane percento v zotrvacnosti
   */
  private setActualLag(percent: number): void {

    // ease out cubic
    const percentChanged = 1 - Math.pow(1 - percent, 3)

    this.actualLag.set(
      percentChanged * this.lagOffsetOriginal.x,
      percentChanged * this.lagOffsetOriginal.y
    )

  }

  /**
   * Spustenie zotrvacnosti
   */
  private setLagStart(): void {

    this.frameLag = aimConfig.lag.frames
    const lagCoef = aimConfig.lag.coef
    this.lagOffsetOriginal.set(this.lastMoveOffset.x * lagCoef, this.lastMoveOffset.y * lagCoef)

  }

  /**
   * Spravovanie zotrvacnosti
   * @param x - X
   * @param y - Y
   */
  private manageLag(x: number, y: number): void {

    if (!aimConfig.lag.active) return

    const moving = x !== 0 || y !== 0

    if (!moving && (this.frameLag === 0 || this.movingInLag)) {

      this.movingInLag = false
      this.setLagStart()

    }

    if (this.frameLag > 0) {

      this.frameLag -= 1

      if (moving) this.movingInLag = true

      const percent = this.frameLag / aimConfig.lag.frames
      this.setActualLag(percent)

    }

  }

  /**
   * zmenime poziciu aiming pointu podla hracovho inputu
   */
  public updateAimingPosition(): void {

    if (MobileDetector.isMobile()) {

      // MOBILNA CAST
      if (!this.isInitShiftDone) {

        this.makeInitAimingShift()

      }

      const joystickStep = aimConfig.sensitivity.joystickStep
      const x = store.getters['MovementState/getPositionX'] * joystickStep.x
      const y = store.getters['MovementState/getPositionY'] * joystickStep.y

      this.moveAimingPoint(x, -y, true)
      return

    }

    // WEBOVA CAST
    if (!this.isInitShiftDone) {

      this.lastMousePosition = this.actualMousePosition.clone()
      this.makeInitAimingShift()

    }

    this.helperVectorDiff.set(
      this.lastMousePosition.x - this.actualMousePosition.x,
      this.lastMousePosition.y - this.actualMousePosition.y
    )

    this.moveAimingPoint(
      -1 * this.helperVectorDiff.x * this.mouseStep.x,
      this.helperVectorDiff.y * this.mouseStep.y,
      true
    )
    this.lastMousePosition = this.actualMousePosition.clone()

  }

  /**
   * Kontrola limitov pri zameriavani na osi X
   * @param x - hodnota na osi X
   * @returns Nova hodnota v limitoch
   */
  private checkAimLimitX(x: number): number {

    const { minX, maxX } = this.limitsToMove
    let newX = x

    if (newX < minX) newX = minX
    if (newX > maxX) newX = maxX

    return newX

  }

  /**
   * Kontrola limitov pri zameriavani na osi Y
   * @param y - hodnota na osi Y
   * @returns Nova hodnota v limitoch
   */
  private checkAimLimitY(y: number): number {

    const { minY, maxY } = this.limitsToMove
    let newY = y

    if (newY < minY) newY = minY
    if (newY > maxY) newY = maxY

    return newY

  }

  /**
   * moves aiming point
   * @param x - how much on X axis should we move
   * @param y - how much on Y axis shoud we move
   * @param withLag - Ci sa riesi aj zotrvacnost alebo nie
   */
  public moveAimingPoint(x: number, y: number, withLag = false): void {

    if (withLag) {

      // najskor nastavime aka by mala byt zotrvacnost (moze vyjst aj nulova)
      this.manageLag(x, y)

      // potom si poznacime offset pre buduce potreby
      this.lastMoveOffset.set(x, y)

      // a nakoniec pripocitame aktualnu hodnotu zotrvacnosti
      x += this.actualLag.x
      y += this.actualLag.y

    }

    this.aimingPoint.position.x = this.checkAimLimitX(this.aimingPoint.position.x - x)
    this.aimingPoint.position.y = this.checkAimLimitY(this.aimingPoint.position.y + y)

    if (aimConfig.debug.showTargetPoint) {

      // pripocitavame aj vietor
      const point = wind.getNewPoint(this.aimingPoint.position)
      this.moveTargetPoint(point)

    }
    if (aimConfig.debug.showTargetPointOriginal) {

      this.moveTargetPoint(this.aimingPoint.position, true)

    }

  }

  /**
   * Pohyb bodu na terci
   * @param aimPoint - Bod, z ktoreho sa bude pocitat bod na terci
   * @param targetPointOriginal - Ci posuvame originalny bod bez vetra alebo nie
   */
  public moveTargetPoint(point: THREE.Vector3, targetPointOriginal = false): void {

    // ked nejde o original, ale o bod ovplyvneny vetrom, tak musime skontrolovat limity
    if (!targetPointOriginal) {

      point.x = this.checkAimLimitX(point.x)
      point.y = this.checkAimLimitY(point.y)

    }

    // spravime raycast kvoli debugu
    this.raycast.set(point, this.RAYCAST_DIR_VECTOR)

    // zoberiem si bod, kde sa pretal s krivkou kde chcem ist
    const intersection = this.raycast.intersectObject(game.getObject3D('envDynamic_TargetHitBox'))?.[0]?.point

    // TODO: co ak je mimo tercu? asi bude treba riesit v ramci nastavovania point-u v parametri
    if (intersection !== undefined) {

      if (targetPointOriginal) {

        this.targetPointOriginal.position.set(intersection.x, intersection.y, intersection.z)

      } else {

        this.targetPoint.position.set(intersection.x, intersection.y, intersection.z)


      }

    }

  }

  /**
   * posunieme pociatocnu poziciu aiming bodu pri myske
   */
  private makeInitAimingShift(): void {

    if (this.isInitShiftDone) return
    this.isInitShiftDone = true

    const { maxY, minY, maxX, minX } = this.limitsToMove

    if (!MobileDetector.isMobile()) {

      // najprv pomerovo posunieme podla pozicie mysky
      const ratioX = 1 - this.lastMousePosition.x / window.innerWidth
      const ratioY = 1 - this.lastMousePosition.y / window.innerHeight

      this.aimingPoint.position.x = minX + (maxX - minX) * ratioX
      this.aimingPoint.position.y = minY + (maxY - minY) * ratioY

    } else {

      this.aimingPoint.position.copy(aimConfig.targetOriginPosition)

    }

    // vypocitanie posunu o random hodnotu
    const { horizontal, vertical } = aimConfig.startAimPosition
    const shiftValueX = Math.random() * (horizontal.max - horizontal.min) + horizontal.min
    const shiftValueY = Math.random() * (vertical.max - vertical.min) + vertical.min

    this.aimingPoint.position.x += shiftValueX
    this.aimingPoint.position.y += shiftValueY

    // kontrola ci sme nevysli z extremov
    if (this.aimingPoint.position.x < minX) this.aimingPoint.position.x = minX
    if (this.aimingPoint.position.x > maxX) this.aimingPoint.position.x = maxX
    if (this.aimingPoint.position.y < minY) this.aimingPoint.position.y = minY
    if (this.aimingPoint.position.y > maxY) this.aimingPoint.position.y = maxY

  }

  /**
   * registrujeme mouse event aby sme mohli zbierat poziciu
   */
  public setMouseStep(): void {

    if (MobileDetector.isMobile()) return

    const gameSizeX = document.getElementById('game-container')?.clientWidth ?? 1
    const gameSizeY = document.getElementById('game-container')?.clientHeight ?? 1

    const { movementRange, sensitivity } = aimConfig
    this.mouseStep.set(
      (movementRange.x / gameSizeX) / sensitivity.screenToMaxTarget.x,
      (movementRange.y / gameSizeY) / sensitivity.screenToMaxTarget.y
    )

  }

  /**
   * Creates target point which we move around
   */
  public createAimingPoint(): void {

    if (game.scene.getObjectByName(this.AIMING_TARGET_POINT)) return
    const geometry = new THREE.SphereGeometry(aimConfig.scopeRadius)
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0xFFFF00)
    })

    this.aimingPoint = new THREE.Mesh(geometry, material)
    this.aimingPoint.name = this.AIMING_TARGET_POINT

    if (!aimConfig.debug.showAimingPoint) this.aimingPoint.visible = false

    this.aimingPoint.position.copy(aimConfig.targetOriginPosition)
    game.scene.add(this.aimingPoint)

  }

  /**
   * Vytvorenie debug bodu, ktory sa nachadza priamo na terci a vychadza z target pointu
   * @param nameSufix - Sufix pre meno objektu, aby sa dali rozlisovat
   */
  public createTargetPoint(nameSufix = ''): void {

    if (game.scene.getObjectByName(`${this.TARGET_POINT}${nameSufix}`)) return

    const geometry = new THREE.SphereGeometry(aimConfig.scopeRadius)
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(nameSufix === '' ? 0x00FF00 : 0xFF0000)
    })

    const targetPoint = new THREE.Mesh(geometry, material)
    targetPoint.name = `${this.TARGET_POINT}${nameSufix}`

    if (
      (!aimConfig.debug.showTargetPoint && nameSufix === '') ||
            (!aimConfig.debug.showTargetPointOriginal && nameSufix !== '')
    ) {

      targetPoint.visible = false

    }

    targetPoint.position.copy(aimConfig.targetOriginPosition)
    game.scene.add(targetPoint)

    if (nameSufix === '') {

      this.targetPoint = targetPoint

    } else {

      this.targetPointOriginal = targetPoint

    }

  }

  /**
   * odstranime target point zo sceny
   */
  public removeObjectsFromScene(): void {

    this.aimingPoint.geometry.dispose()

    if (this.aimingPoint.material instanceof Array) {

      this.aimingPoint.material.forEach((material: THREE.Material) => material.dispose())

    } else {

      this.aimingPoint.material.dispose()

    }

    game.scene.remove(this.aimingPoint)

  }

  /**
   * Resetovanie property objektu
   */
  public reset(): void {

    this.isInitShiftDone = false
    this.deviation = 0
    this.direction = Direction.N
    this.setLimitsToMove()

    this.lastMoveOffset.set(0, 0)

  }

}

export const aimingDirectionManager = new AimingDirectionManager()
