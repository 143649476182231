import store from '@/store'
import {
  trainingManager,
  modes,
  corePhasesManager,
  requestManager
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { trainingConfig } from '@/app/config'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 6

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   *
   * @param arrowPoints - data aktualneho zasahu
   */
  public countArrowPoints(arrowPoints: { points: number, countX: number, count10: number }): void {

    const { trainingTask } = trainingConfig
    let configPointer = arrowPoints.points
    if (arrowPoints.countX > 0) {

      configPointer += 1

    }

    const task = Tasks[`arrow${corePhasesManager.disciplineActualAttempt}` as keyof typeof Tasks]

    console.log(task)
    this.saveTaskValue(task, trainingTask[configPointer] / 100)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}, ${trainingManager.getTpPerTask()}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      store.commit('TrainingState/EDIT_TASK', {
        text: `trainingTask${requestManager.disciplineID}-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    // this.saveTaskValue(Tasks.timeSecondShots, this.getTimeSecondShots())

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    trainingManager.resetAll()

  }

}

export const trainingTasks = new TrainingTasks()
