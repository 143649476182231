import { THREE } from '@powerplay/core-minigames'
/**
 * Konfig pre mierenie
 */
export const aimConfig = {

  /** debug zobrazenia */
  debug: {

    /** zobraz mieritko pred tercom */
    showAimingPoint: false,

    /** zobraz mieritko na terci s vetrom */
    showTargetPoint: false,

    /** Zobraz mieritko na terci bez vetra */
    showTargetPointOriginal: false

  },

  /** startovacia pozicia mierenia */
  targetOriginPosition: new THREE.Vector3(2.562, 1.84, 135.857),

  /** Kolko frameov bude na zaciatku mierenia freeznuty input hraca */
  inputFreezeFramesOnStart: 10,

  /** Pozicia stredu terca */
  targetCenterPosition: new THREE.Vector3(2.562, 1.84, 135.857),

  /** Nastavenia kamery */
  camera: {

    /** zakladne nastavenia kamery */
    near: 0.03,
    far: 1000,
    fovDesktop: 5,
    fovMobile: 5,

    /** pozicia z ktorej strielame */
    wrapperPosition: new THREE.Vector3(2.562, 1.84, 66.357),

    /** Nastavenie atleta pri tejto kamere */
    athlete: {

      position: new THREE.Vector3(0.0867, -1.6789, 0.7),
      rotation: new THREE.Euler(-0.0115, 0.0225, 0)

    }

  },

  /** o kolko od origin pointu sa vieme max. posunut */
  movementRange: new THREE.Vector2(0.7, 0.7),

  /** citlivost */
  sensitivity: {

    /** po kolkych % obrazovky presunieme target o 100% */
    screenToMaxTarget: new THREE.Vector2(0.4, 0.4),

    /** citlivost joystiku */
    joystickStep: new THREE.Vector2(0.05, 0.05)

  },

  /** o kolko sa posunie zaciatocna pozicia na zaciatku. Neprekroci limit z movementRange */
  startAimPosition: {

    vertical: {
      max: 0.5,
      min: -0.5
    },

    horizontal: {
      max: 0.5,
      min: -0.5
    }

  },

  /** polomer mieritka */
  scopeRadius: 0.03,

  /** zotrvacnost/dojazd pri miereni */
  lag: {

    /** Ci je aktivna/y */
    active: true,

    /** Pocet frameov, kolko bude trvat */
    frames: 30,

    // koeficient pre vynasobenie posledneho offsetu
    coef: 2

  },

  /** trasenie ruk */
  shake: {

    /** Ci je aktivne */
    active: false,

    /** Od kolkatich frameov sa zacne triast */
    framesMin: 5 * 30,

    /** Od kolkatich frameov uz nastal maximalny efekt trasenia */
    framesMax: 10 * 30,

    /** ako casto sa bude menit smer */
    directionFrames: 6,

    /** sanca, ze sa nebude menit smer v % */
    chanceSameDirection: 0.3,

    /** koeficient vychylovania */
    coef: 2,

    /** minimalna rychlost vychylovania na osi x a y */
    minStep: 0.002,
    minStepMobile: 0.0005,

    /** maximalna rychlost vychylovania na osi x a y */
    maxStep: 0.004,
    maxStepMobile: 0.0025,

  },

  /** odchylka */
  deviation: {

    /** Ci je aktivna odchylka */
    active: false,

    /** max odchylka */
    max: 0.3,

    /** koeficient pre odchylku */
    coef: 0.003

  }

}