import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface ReleasePhaseState {
  isActive: boolean,
  markPosition: number
}

const initialState = () => ({
  isActive: false,
  markPosition: 0
})

const releasePhaseState: Module<ReleasePhaseState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    isActiveBar: (moduleState: ReleasePhaseState) => moduleState.isActive,
    getMarkPosition: (moduleState: ReleasePhaseState) => moduleState.markPosition
  },

  mutations: {
    RESET: (moduleState: ReleasePhaseState) => {

      Object.assign(moduleState, initialState())

    },
    SET_ACTIVE: (moduleState: ReleasePhaseState, isActive: boolean ) => {

      moduleState.isActive = isActive

    },
    SET_MARK_POSITION: (moduleState: ReleasePhaseState, markPosition: number ) => {

      moduleState.markPosition = markPosition

    }
  }
}

export default releasePhaseState
