
import { scoreDisplayPanelConfig } from '@/app/config'
import {
  PlayerTypes,
  type ScoreDisplayPanelData
} from '@/app/types'
import {
  game,
  THREE
} from '@powerplay/core-minigames'

/**
 * Trieda pre obrazovku so skore
 */
export class ScoreDisplayPanel {

  /** Obrazovky pre hracov */
  private panels: ScoreDisplayPanelData = {
    [PlayerTypes.player]: [],
    [PlayerTypes.opponent]: [],
  }

  /**
   * Vytvorenie obrazoviek
   */
  public create(): void {

    this.setUpGlobal()
    this.setUpPanel(PlayerTypes.player)
    this.setUpPanel(PlayerTypes.opponent)

  }

  /**
   * Globalbe nastavenie obrazoviek
   */
  private setUpGlobal(): void {

    const mesh = game.getMesh('envDynamic_Display_PTS')
    mesh.position.z += scoreDisplayPanelConfig.meshesShiftZ
    mesh.updateMatrix()

    const material = mesh.material as THREE.MeshBasicMaterial
    const map = material.map
    if (map) map.minFilter = THREE.LinearMipmapLinearFilter

  }

  /**
   * Nastavenie jedneho cisla obrazovky
   * @param type - typ obrazovky
   * @param index - index cifry obrazovky
   */
  private setUpPanelDigit(type: PlayerTypes, index: number): void {

    const mesh = game.getMesh(`envDynamic_Display_${type}_${index + 1}`)
    mesh.position.z += scoreDisplayPanelConfig.meshesShiftZ
    mesh.matrixAutoUpdate = true

    this.panels[type][index] = {
      mesh,
      originalUVs: mesh.geometry.attributes.uv.clone()
    }

  }

  /**
   * Nastavenie jednej obrazovky
   * @param type - typ obrazovky
   */
  private setUpPanel(type: PlayerTypes): void {

    this.setUpPanelDigit(type, 0)
    this.setUpPanelDigit(type, 1)

  }

  /**
   * Zmena UV na meshi
   * @param index - index cifry obrazovky
   * @param type - typ obrazovky
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   */
  private changeUVs(index: number, type: PlayerTypes, indexU: number, indexV: number): void {

    const data = this.panels[type][index]
    if (!data) return

    const uvAttribute = data.mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = data.originalUVs.getX(i) + (indexU / scoreDisplayPanelConfig.digitsInTextureOneDir)
      const v = data.originalUVs.getY(i) + (indexV / scoreDisplayPanelConfig.digitsInTextureOneDir)

      uvAttribute.setXY(i, u, v)

    }
    uvAttribute.needsUpdate = true

  }

  /**
   * Zmena cifry na konkretnej casti obrazovky
   * @param number - Nova hodnota cifry
   * @param index - index cifry obrazovky
   * @param type - typ obrazovky
   */
  private changeDigitOnPanel(number: number, index: number, type: PlayerTypes): void {

    const indexU = number % scoreDisplayPanelConfig.digitsInTextureOneDir
    const indexV = Math.floor(number / scoreDisplayPanelConfig.digitsInTextureOneDir)
    this.changeUVs(index, type, indexU, indexV)

  }

  /**
   * Nastavenie cisla na obrazovke zo skore
   * @param number - cislo, ktore chceme zobrazit
   * @param type - typ obrazovky
   */
  public setNumberOnPanel(number: number, type: PlayerTypes): void {

    let digit1 = 0
    let digit2 = number

    if (number >= 10) {

      digit1 = Math.floor(number / 10)
      digit2 = number % 10

    }

    this.changeDigitOnPanel(digit1, 0, type)
    this.changeDigitOnPanel(digit2, 1, type)

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.setNumberOnPanel(0, PlayerTypes.player)
    this.setNumberOnPanel(0, PlayerTypes.opponent)

  }

}

export const scoreDisplayPanel = new ScoreDisplayPanel()
