import {
  pathAssetsGlobal,
  pathAssets
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.athleteRaceWhiteMan]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoMan]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackMan]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackWoman]: {
    ext: 'ktx2',
    version: 231205,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothes]: {
    ext: 'ktx2',
    version: 240509,
    femaleVersion: 2405092,
    genderActive: true,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent1]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 2,
    femaleVersion: 2,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteEquipment]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDir}/athlete/`,
  },

  [TexturesNames.athleteEquipmentOpponent1]: {
    ext: 'ktx2',
    dir: `${texturesDir}/athlete/`,
  },
  [TexturesNames.athleteHair]: {
    ext: 'ktx2',
    version: 231204,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradient]: {
    ext: 'ktx2',
    version: 3,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradientVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.concrete]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lights]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.metal]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.panel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.seats]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.stairs]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.trapNet]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.grassUncut]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.numbers]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.paving]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.trees]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.glass]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lattice]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.npc]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.flags]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.ppsLogo]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.screens]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.screensVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.targetMarkers]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.target]: {
    ext: 'ktx2',
    version: 231214,
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.digitalDisplay]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.digitalDisplayLongDistance]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skybox]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxMountain]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxClouds]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding1]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skyboxBuilding2]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.envLightmap]: {
    ext: 'ktx2',
    version: 231213,
    dir: `${texturesDir}/env/`,
    returnTextureLightmap: true
  },
  [TexturesNames.flagGradient]: {
    ext: 'ktx2',
    returnTextureLightmap: true,
    dir: `${texturesDirGlobal}/env/`
  },


}
