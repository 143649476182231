import { type MaterialDataObject } from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.athlete]: {
    meshesArray: [
      'body_low', 'hat_1_low', 'hands', 'f_body_low', 'f_hat_1_low'
    ],
    textureName: TexturesNames.athleteClothes,
    playerIndex: 0
  },
  [MaterialsNames.athleteOpponent1]: {
    meshesArray: [
      'body_low_opponent_1', 'f_body_low_opponent_1'
    ],
    textureName: TexturesNames.athleteClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.athleteEquipment]: {
    meshesArray: [
      'arrow', 'bow_low', 'quiver_low', 'athlete_arrow_2', 'f_quiver_low'
    ],
    textureName: TexturesNames.athleteEquipment,
  },
  [MaterialsNames.athleteEquipmentOpponent1]: {
    meshesArray: [
      'arrow_opponent_1', 'bow_low_opponent_1', 'quiver_low_opponent_1', 'athlete_arrow_2_opponent_1',
      'f_quiver_low_opponent_1'
    ],
    textureName: TexturesNames.athleteEquipmentOpponent1,
  },
  [MaterialsNames.athleteHair]: {
    meshesArray: ['hair_low', 'tail_low', 'f_hair_low', 'f_tail_low'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.athleteHairOpponent1]: {
    meshesArray: ['hair_low_opponent_1', 'tail_low_opponent_1', 'f_hair_low_opponent_1', 'f_tail_low_opponent_1'],
    textureName: TexturesNames.athleteHair,
    transparent: true,
    color: 0xFFD492
  },
  [MaterialsNames.audience]: {
    textureName: TexturesNames.audience,
    meshesArray: ['ArcheryRange_Mesh_12'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.colorGradient]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: ['ArcheryRange_Mesh_4'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.concrete]: {
    textureName: TexturesNames.concrete,
    meshesArray: ['ArcheryRange_Mesh_14', 'ArcheryRange_Mesh_5'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.lights]: {
    textureName: TexturesNames.lights,
    meshesArray: ['ArcheryRange_Mesh_11'],
    // lightmap: TexturesNames.lightmapHill,
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.metal]: {
    textureName: TexturesNames.metal,
    meshesArray: ['ArcheryRange_Mesh_6', 'ArcheryTarget1_Mesh001'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.metalWithoutLM]: {
    textureName: TexturesNames.metal,
    meshesArray: ['ArcheryTargets_Mesh'],
    vertexColors: true,
  },
  [MaterialsNames.panel]: {
    textureName: TexturesNames.panel,
    meshesArray: ['ArcheryRange_Mesh_3'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.seats]: {
    textureName: TexturesNames.seats,
    meshesArray: ['ArcheryRange_Mesh_14'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
    alpha: 0.5, // alphatest
    // transparent: true
  },
  [MaterialsNames.stairs]: {
    textureName: TexturesNames.stairs,
    meshesArray: ['ArcheryRange_Mesh_13'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.dots]: {
    textureName: TexturesNames.trapNet,
    meshesArray: ['ArcheryRange_Mesh_8'],
    lightmap: TexturesNames.envLightmap,
    opacity: 0.8,
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.grassUncut]: {
    textureName: TexturesNames.grassUncut,
    meshesArray: ['ArcheryRange_Mesh'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.numbers]: {
    textureName: TexturesNames.numbers,
    meshesArray: ['ArcheryRange_Mesh_9', 'ArcheryTarget1_Mesh001_2', 'ArcheryRange_Mesh_18', 'ArcheryTargets_Mesh_2'],
    // lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
  },
  [MaterialsNames.paving]: {
    textureName: TexturesNames.paving,
    meshesArray: ['ArcheryRange_Mesh_2'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.trees]: {
    textureName: TexturesNames.trees,
    meshesArray: ['ArcheryRange_Mesh_1'],
    lightmap: TexturesNames.envLightmap,
    alpha: 0.5, // alphatest
    vertexColors: true,
  },
  [MaterialsNames.glass]: {
    textureName: TexturesNames.glass,
    meshesArray: ['ArcheryRange_Mesh_9'],
    transparent: true,
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.lattice]: {
    textureName: TexturesNames.lattice,
    meshesArray: ['ArcheryRange_Mesh_10'],
    alpha: 0.5, // alphatest
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.npc]: {
    textureName: TexturesNames.npc,
    meshesArray: ['ArcheryRange_Mesh_15'],
    lightmap: TexturesNames.envLightmap,
    vertexColors: true,
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['flags_Flag_Base'],
    lightmap: TexturesNames.flagGradient
  },
  [MaterialsNames.screens]: {
    textureName: TexturesNames.screens,
    meshesArray: ['ArcheryRange_Mesh_16']
  },
  [MaterialsNames.ppsLogo]: {
    textureName: TexturesNames.ppsLogo,
    alpha: 0.5,
    meshesArray: ['ArcheryRange_Mesh_17']
  },
  [MaterialsNames.targetMarkers]: {
    meshesArray: [
      'envDynamic_HitMarker_1', 'envDynamic_HitMarker_2', 'envDynamic_HitMarker_3',
      'envDynamic_HitMarker_4', 'envDynamic_HitMarker_5', 'envDynamic_HitMarker_6',
      'envDynamic_HitMarker_7', 'envDynamic_HitMarker_8', 'envDynamic_HitMarker_9',
      'envDynamic_HitMarker_10', 'envDynamic_HitMarker_11', 'ArcheryTargets_Mesh_3'
    ],
    textureName: TexturesNames.target
  },
  [MaterialsNames.targetMarkersGlow]: {
    meshesArray: ['envDynamic_HitMarker_BG'],
    color: 0x00ff33
  },
  [MaterialsNames.digitalDisplay]: {
    textureName: TexturesNames.digitalDisplayLongDistance, // texturu pre blizko budeme davat dynamicky
    transparent: true,
    vertexColors: true,
    meshesArray: [
      'envDynamic_Display_PTS', 'envDynamic_Display_Player1_1', 'envDynamic_Display_Player1_2',
      'envDynamic_Display_Player2_1', 'envDynamic_Display_Player2_2'
    ]
  },
  [MaterialsNames.skybox]: {
    textureName: TexturesNames.skybox,
    meshesArray: ['SkyboxDay_Skybox_BG']
  },
  [MaterialsNames.skyboxMontain]: {
    textureName: TexturesNames.skyboxMountain,
    alpha: 0.5,
    meshesArray: ['SkyboxDay_Skybox_FG']
  },
  [MaterialsNames.skyboxClouds]: {
    textureName: TexturesNames.skyboxClouds,
    meshesArray: ['SkyboxDay_Skybox_Clouds'],
    transparent: true
  },
  [MaterialsNames.skyboxBuilding1]: {
    textureName: TexturesNames.skyboxBuilding1,
    meshesArray: ['Buildings_Mesh_1'],
    alpha: 0.5
  },
  [MaterialsNames.skyboxBuilding2]: {
    textureName: TexturesNames.skyboxBuilding2,
    meshesArray: ['Buildings_Mesh'],
    alpha: 0.5
  },
  [MaterialsNames.onlyVertexColors]: {
    isDefault: true,
    meshesArray: [''],
    vertexColors: true
  }

}
