import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'trainingTask12-0',
    replace: [
      {
        original: '[number]',
        new: '1'
      }
    ]
  },
  {
    text: 'trainingTask12-1',
    replace: [
      {
        original: '[number]',
        new: '2'
      }
    ]
  },
  {
    text: 'trainingTask12-2',
    replace: [
      {
        original: '[number]',
        new: '3'
      }
    ]
  },
  {
    text: 'trainingTask12-3',
    replace: [
      {
        original: '[number]',
        new: '4'
      }
    ]
  },
  {
    text: 'trainingTask12-4',
    replace: [
      {
        original: '[number]',
        new: '5'
      }
    ]
  },
  {
    text: 'trainingTask12-5',
    replace: [
      {
        original: '[number]',
        new: '6'
      }
    ]
  },
  {
    text: 'tutorialTask12-1',
    replace: [
      {
        original: '[number]',
        new: '[number]'
      }
    ]
  },
  {
    text: 'tutorialText12-2',
    replace: [
      {
        original: '[number]',
        new: '[number]'
      }
    ]
  },
  {
    text: 'tutorialText12-3',
    replace: [
      {
        original: '[number]',
        new: '[number]'
      }
    ]
  },

]
