/**
 * Mena sekcie
 */
export enum SectionNames {
  mobileSidePick = 'mobileSidePick',
  introductionNewInstructor = 'introductionNewInstructor',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionFourth = 'startSectionFourth',
  startSectionFifth = 'startSectionFifth',
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  attemptWithTime = 'attemptWithTime',
  attemptWithWind = 'attemptWithWind',
  attemptOutOfTime = 'attemptOutOfTime',
  finish = 'finish',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  shoot30 = 'tutorialTask2-1',
}
