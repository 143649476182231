import {
  animationsConfigBow,
  gameConfig
} from '@/app/config'
import {
  AnimationsManager,
  THREE,
  fpsManager,
  game
} from '@powerplay/core-minigames'
import { ModelsNames } from '@/app/types'
import type { Athlete } from '../athlete'

/**
 * Trieda pre luk
 */
export class Bow {

  /** Rig pre luk, resp vsetky kosti a skinned meshe v jednom */
  public bowRig!: THREE.Object3D

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vytvorenie luku
   */
  public create(): void {

    // musime dat rig luku prec z objektu hraca
    this.bowRig = this.athlete.athleteObject.getObjectByName('bow_rig') as THREE.Object3D
    game.scene.add(this.bowRig)

    this.resetPositionRotationBowBone()

    // skinned meshu musime vypnut frustumCulled, lebo sa to sprava, akoby bol na povodnej pozicii
    const bowSufix = this.athlete.playable ? '' : '_opponent_1'
    const bowSkinnedMesh = this.bowRig.getObjectByName(`bow_low${bowSufix}`) as THREE.Object3D
    if (bowSkinnedMesh) {

      bowSkinnedMesh.frustumCulled = false
      bowSkinnedMesh.castShadow = true

    }

    // animacie
    this.animationsManager = new AnimationsManager(
      this.bowRig,
      animationsConfigBow,
      game.animations.get(ModelsNames.athlete),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // nastavenie rigu, aby to sedelo
    const bowHelperBone = this.athlete.athleteObject.getObjectByName('bow_helper')
    if (!bowHelperBone || !this.bowRig) return
    bowHelperBone.add(this.bowRig)

  }

  /**
   * Resetovanie pozicie a rotacie pre hlavnu kost luku
   */
  private resetPositionRotationBowBone(): void {

    const bowBone = this.bowRig.getObjectByName('bow_main')
    if (bowBone) {

      bowBone.position.set(0, 0, 0)
      bowBone.rotation.set(0, 0, 0)

    }

  }

  /**
   * Nastavenie viditelnosti
   * @param visibility - Viditelnost
   */
  public setVisibility(visibility: boolean): void {

    this.bowRig.visible = visibility

  }

  /**
   * Aktualizovanie luku
   * @param delta - Delta
   */
  public update(delta: number): void {

    // aktualizujeme animacie
    this.animationsManager.update(delta)

    this.resetPositionRotationBowBone()

  }

  /**
   * reset luku
   */
  public reset(): void {

    this.animationsManager.resetAll()

  }

}

