<template>
  <div
    v-if="isActive"
    class="draw-bar"
  >
    <horizontal-bar-with-ideal
      :glow="false"
      :mark="markPosition"
      :gradient="barValues"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { HorizontalBarWithIdeal } from '@powerplay/core-minigames-ui-ssm'
import { drawConfig } from '@/app/config'
import { DrawTypes } from '@/app/types'

export default defineComponent({
  name: 'DrawBar',
  components: {
    HorizontalBarWithIdeal
  },
  computed: {
    ...mapGetters({
      activeBar: 'DrawPhaseState/isActiveBar',
      markPosition: 'DrawPhaseState/getMarkPosition'
    }),
    isActive() {

      return drawConfig.type === DrawTypes.bar && this.activeBar

    },
    barValues() {

      return drawConfig.barType.barColorLimits

    }
  }
})

</script>

<style lang="less" scoped>
.draw-bar {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>
