<template>
  <archery-game />
</template>

<script lang="ts">
import ArcheryGame from '@/components/ArcheryGame.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ArcheryGame
  },
})

</script>