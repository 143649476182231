import store from '@/store'
import { shootingConfig } from './config'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import {
  BlueBoxTextType,
  DisciplinePhases
} from './types'
import {
  corePhasesManager,
  fpsManager,
  gsap,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import { startPhaseStateManager } from './phases/StartPhase/StartPhaseStateManager'
import { StateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { trainingTasks } from './modes/training'
import type { DrawPhaseWithHold } from './phases/DrawPhase/DrawPhaseWithHold'

/**
 * Trieda pre casovy limit
 */
export class TimeLimitManager {

  /** Pocitadlo frameov pre casovy limit */
  private frameCounter = 0

  /** Ci je aktivny */
  private isActive = false

  /** ci delay na out of time skoncil */
  private outOfTimeDelayEnded = false

  /** delay na out of time */
  private outOfTimeDelayActive = false

  /**
   * Nastavenie aktivnosti casoveho limitu
   * @param active - Ci sa ma aktivovat alebo deaktivovat
   */
  public setActive(active: boolean): void {

    this.isActive = active

  }

  /**
   * Skontrolovanie casoveho limitu
   * @returns Ci uz skoncil casovy limit
   */
  public check(): boolean {

    if (!this.isActive) return false

    this.frameCounter += 1
    let time = (shootingConfig.timeLimitShot - this.frameCounter) / fpsManager.fpsLimit
    if (time < 0) time = 0
    store.commit(
      'StartPhaseState/SET_START_TIMER',
      time
    )

    // kontrolujeme ci sa ma uz skoncit na zaklade casoveho limitu
    if (this.frameCounter >= shootingConfig.timeLimitShot) {

      store.commit(
        'WindState/SET_SHOW_COUNTDOWN',
        false
      )
      console.log('CASOVY LIMIT UPLYNUL')

      // musime si zistit, ci mame plne natiahnuty luk a ak ano, tak vystrelit
      if (disciplinePhasesManager.phaseAim.isActive) {

        // vystrelime
        disciplinePhasesManager.phaseAim.finishPhase(false)
        return true

      } else {

        if (!this.outOfTimeDelayActive) {

          if (
            disciplinePhasesManager.getActualPhase() === DisciplinePhases.draw &&
            (disciplinePhasesManager.phaseDraw as DrawPhaseWithHold).activeDrawForward
          ) {

            disciplinePhasesManager.phaseDraw.finishPhase(true)
            disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.aim)
            return false

          }

          store.commit('ActionButtonState/SET_SHOW_JOYSTICK', false)
          store.commit('UiState/SET_BLUE_BOX_TEXT_TYPE', BlueBoxTextType.hidden)

          const showFirstLine = true
          const showSecondLine = false
          startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)

          store.commit('TextMessageState/SET_STATE', {
            showFirstLine: showFirstLine,
            showSecondLine: showSecondLine,
            firstLineText: 'text-out-of-time',
            firstLineTextType: 0,
            secondLineTextType: 2,
            secondLineLeftNumber: 0,
            showMessage: true,
            showType: 3
          })
          this.outOfTimeDelayActive = true
          playersManager.setStandings()
          store.commit(
            'MiniTableState/SET_STATE',
            {
              rowsData: StateManager.getDataForMiniTable(),
              show: corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency !== 0 &&
                !modes.isTutorial() && !modes.isTrainingMode()
            }
          )

          trainingTasks.countArrowPoints({
            points: 0,
            countX: 0,
            count10: 0
          })
          gsap.to({}, {
            onComplete: () => {

              // ukoncime
              disciplinePhasesManager.finishActualPhase(true)
              disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.finish)
              store.commit(
                'WindState/SET_SHOW_WIND',
                false
              )
              store.commit(
                'WindState/SET_SHOW_WIND_CENTER',
                false
              )
              store.commit(
                'WindState/SET_SHOW_COUNTDOWN',
                false
              )
              this.outOfTimeDelayEnded = true

              startPhaseStateManager.hideTextMessage()
              tutorialFlow.outOfTime()

            },
            duration: 1.5
          })

        }

        return this.outOfTimeDelayEnded


      }

    }

    return false

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.frameCounter = 0
    this.isActive = false
    this.outOfTimeDelayEnded = false
    this.outOfTimeDelayActive = false

  }

}

export const timeLimitManager = new TimeLimitManager()
