import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  BowAnimationsNames,
  PlayerAnimationsNames,
  PlayerStates
} from '@/app/types'
import type { Athlete } from './Athlete'
import {
  CallbackAnimationTypes,
  corePhasesManager,
  modes,
  playersManager,
  trainingManager
} from '@powerplay/core-minigames'
import { player } from './player'

/**
 * Dedikovany manazer animacii pre hraca/superov.
 */
export class AthleteAnimationManager {

  /** ci sme uz nastavili podla stavov veci */
  private statesActive = {
    [PlayerStates.idle]: false,
    [PlayerStates.prepare]: false,
    [PlayerStates.idle2]: false,
    [PlayerStates.loading]: false,
    [PlayerStates.drawing]: false,
    [PlayerStates.shooting]: false,
    [PlayerStates.emotion]: false,
  }

  /** aktualna animacia - TODO rozsir o vsetky animacie mimo konecnej */
  public actualAnimation?: PlayerAnimationsNames

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Zmena rychlosti animacii
   * @param speed - Nova rychlost animacii
   */
  private changeAnimationSpeed(speed: number) {

    this.athlete.animationsManager.setSpeed(speed)

  }

  /**
   * Reset rychlosti animacii
   */
  private resetAnimationSpeed() {

    this.athlete.animationsManager.resetSpeed()

  }

  /**
   * Riesenie veci pre prepare stav
   * @returns True, ak ide o dany stav
   */
  private isPrepareState(): boolean {

    const isPreparing = this.athlete.isState(PlayerStates.prepare)
    if (!this.statesActive[PlayerStates.prepare] && isPreparing) {

      const animation = this.athlete.playable ? PlayerAnimationsNames.prepare1 : PlayerAnimationsNames.prepare2
      this.statesActive[PlayerStates.prepare] = true
      this.athlete.animationsManager.resetAnimationTime(animation)
      this.athlete.animationsManager.changeTo(animation)

    }

    return isPreparing

  }

  /**
   * Riesenie veci pre druhy idle stav
   * @returns True, ak ide o dany stav
   */
  private isIdle2State(): boolean {

    const isIdle2 = this.athlete.isState(PlayerStates.idle2)
    if (!this.statesActive[PlayerStates.idle2] && isIdle2) {

      this.statesActive[PlayerStates.idle2] = true
      // TODO neskor sa bude random davat podla toho ako bude aj super a bude to to opacne

      this.athlete.animationsManager.changeTo(!this.athlete.playable ?
        PlayerAnimationsNames.idle1 :
        PlayerAnimationsNames.idle2)

    }

    return isIdle2

  }

  /**
   * Riesenie veci pre loading stav
   * @returns True, ak ide o dany stav
   */
  private isLoadingState(): boolean {

    const isLoading = this.athlete.isState(PlayerStates.loading)
    if (!this.statesActive[PlayerStates.loading] && isLoading) {

      this.statesActive[PlayerStates.loading] = true
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.loading)

    }

    return isLoading

  }

  /**
   * Riesenie veci pre draw stav
   * @returns True, ak ide o dany stav
   */
  private isDrawingState(): boolean {

    const isDrawing = this.athlete.isState(PlayerStates.drawing)

    if (isDrawing) {

      if (!this.statesActive[PlayerStates.drawing]) {

        this.statesActive[PlayerStates.drawing] = true
        this.athlete.animationsManager.changeToPaused(PlayerAnimationsNames.drawing)
        this.athlete.bow.animationsManager.changeToPaused(BowAnimationsNames.drawing)

      }

      const percent = disciplinePhasesManager.phaseDraw.getPhasePercent() * 100
      this.athlete.animationsManager.manualyUpdateTimeByPercent(
        PlayerAnimationsNames.drawing,
        percent
      )
      this.athlete.bow.animationsManager.manualyUpdateTimeByPercent(
        BowAnimationsNames.drawing,
        percent
      )

    }

    return isDrawing

  }

  /**
   * Riesenie veci pre draw stav
   * @returns True, ak ide o dany stav
   */
  private isShootingState(): boolean {

    const isShooting = this.athlete.isState(PlayerStates.shooting)

    if (!this.statesActive[PlayerStates.shooting] && isShooting) {

      this.statesActive[PlayerStates.shooting] = true
      this.athlete.animationsManager.changeTo(PlayerAnimationsNames.shoot)
      this.athlete.bow.animationsManager.changeTo(BowAnimationsNames.shoot)

    }

    return isShooting

  }

  /**
   * Vratenie konecne emocie v treningu
   * @returns Emocia
   */
  private getTrainingEndEmotion(): PlayerAnimationsNames {

    const tasks = trainingManager.getTrainingTasks()
    const sum = tasks.reduce((prev, current) => prev + current.value, 0)
    const average = sum / tasks.length

    // default je 0-2 hviezd
    let animation = PlayerAnimationsNames.idle

    // ak 3 hviezdy
    if (average > 0.9) animation = PlayerAnimationsNames.happy

    return animation

  }

  /**
   * Vratenie emocie hraca po strelbe
   * @returns Emocia
   */
  private getEmotion(): PlayerAnimationsNames {

    if (modes.isTrainingMode()) {

      return this.getTrainingEndEmotion()

    }

    let animation
    const lastPoints = playersManager.getPlayer().resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main ?? 0
    const position = playersManager.getPlayerActualPosition()
    const randomHappyAnimation = Math.round(Math.random()) === 0 ?
      PlayerAnimationsNames.happy :
      PlayerAnimationsNames.happy2

    if (disciplinePhasesManager.isThirdAttempt()) {

      animation = PlayerAnimationsNames.idle
      if (lastPoints === 10 || position === 1) animation = randomHappyAnimation
      if (position >= 6) animation = PlayerAnimationsNames.sad

    } else {

      animation = PlayerAnimationsNames.sad
      if (position === 1) animation = PlayerAnimationsNames.happy3
      if (!modes.isEventBossFight() && (position === 2 || position === 3)) {

        animation = randomHappyAnimation

      }

    }

    return animation

  }

  /**
   * Riesenie veci pre emotion stav
   * @returns True, ak ide o dany stav
   */
  private isEmotionState(): boolean {

    const isEmotion = this.athlete.isState(PlayerStates.emotion)

    if (!this.statesActive[PlayerStates.emotion] && isEmotion) {

      this.statesActive[PlayerStates.emotion] = true
      const emotion = this.getEmotion()

      this.athlete.animationsManager.addAnimationCallback(
        emotion,
        CallbackAnimationTypes.end,
        () => {

          this.athlete.animationsManager.removeAnimationCallback(
            emotion,
            CallbackAnimationTypes.end
          )

          player.animationsManager.setSpeed(0.5)
          player.animationsManager.crossfadeTo(PlayerAnimationsNames.afterEmotion, 0.01, true, false)

        }
      )

      player.animationsManager.changeTo(emotion)

    }

    return isEmotion

  }

  /**
   * Samotna logika
   */
  private animationLogic(): void {

    this.isEmotionState()
    this.isShootingState()
    this.isDrawingState()
    this.isLoadingState()
    this.isIdle2State()
    this.isPrepareState()

  }

  /**
   * Update metoda volana v move metode velocity manazera
   */
  public update(): void {

    this.animationLogic()

  }

  /**
   * Zresetovanie veci
   */
  public reset(): void {

    this.actualAnimation = undefined
    this.statesActive = {
      [PlayerStates.idle]: false,
      [PlayerStates.prepare]: false,
      [PlayerStates.idle2]: false,
      [PlayerStates.loading]: false,
      [PlayerStates.drawing]: false,
      [PlayerStates.shooting]: false,
      [PlayerStates.emotion]: false,
    }

    if ((corePhasesManager.disciplineActualAttempt) % corePhasesManager.provisionalResultsFrequency !== 0) {

      this.athlete.setState(PlayerStates.drawing)

    }

  }

}
