import {
  flagsConfig,
  gameConfig
} from '@/app/config'
import {
  DisciplinePhases,
  TexturesNames
} from '@/app/types'
import { scoreDisplayPanel } from './ScoreDisplayPanel'
import {
  game,
  gsap,
  THREE,
  fpsManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '../athlete/player'

/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** Tween pre zvyraznenu zonu trafenia */
  private targetMarkersTween?: gsap.core.Timeline

  /** Mesh pre zeleny bg pre efekt trafenia zony na terci */
  private targetMarkersBgMesh!: THREE.Mesh

  /** Posledny nastaveny index pre efekt zvyraznenia */
  private lastIndexTargetMarkers = 1

  /** Mesh pre oblaky */
  private skyboxCloudsMesh!: THREE.Mesh

  /** options pre meshe vlajok */
  private flagMeshesOptions: {mesh: THREE.Mesh, indexFrom: number, indexTo: number, actualRotation: number}[] = []

  /** progress vlnenia vlajok */
  private progress = 0

  /** Lightmapa vlajok pre hybanie */
  private flagsLightMap!: THREE.Texture | null

  /** Mesh pre terc ako shadow plane */
  public targetBgMesh!: THREE.Mesh

  /** Ci je povolene hybanie vlajkami */
  public movingFlagsEnabled = true

  /** Ci je povolene hybanie skyboxom */
  public movingSkyboxEnabled = true


  /**
   * Vytvorenie prostredia
   */
  public create(): void {

    game.getMesh('envDynamic_TargetHitBox').visible = false
    scoreDisplayPanel.create()
    this.setTargetMarkers()
    this.targetMarkersBgMesh = game.getMesh('envDynamic_HitMarker_BG')
    this.targetMarkersBgMesh.visible = false

    // oblaky
    this.skyboxCloudsMesh = game.getMesh('SkyboxDay_Skybox_Clouds')
    this.skyboxCloudsMesh.matrixAutoUpdate = true
    const cloudsMaterial = this.skyboxCloudsMesh.material as THREE.MeshBasicMaterial
    const cloudsTexture = cloudsMaterial.map
    if (cloudsTexture) {

      cloudsTexture.wrapS = THREE.ClampToEdgeWrapping
      cloudsTexture.wrapT = THREE.ClampToEdgeWrapping

    }
    game.getMesh('SkyboxDay_Skybox_FG').renderOrder = 1

    const glassMesh = game.getMesh('ArcheryRange_Mesh_9')
    glassMesh.renderOrder = 5
    const mesh = game.getMesh('flags_Flag_Base')
    const material = mesh.material as THREE.MeshBasicMaterial
    this.flagsLightMap = material.lightMap
    this.setFlags()

    // tiene na terc
    this.targetBgMesh = game.getMesh('envDynamic_HitMarker_BG').clone()
    this.targetBgMesh.name += '_cloned'
    game.shadowsManager.setShadowMaterialToMesh(this.targetBgMesh)
    this.targetBgMesh.translateY(0.01)
    this.targetBgMesh.updateMatrix()
    game.scene.add(this.targetBgMesh)

  }

  /**
   * nastavenie vlajok
   */
  public setFlags(): void {

    const mesh = game.getMesh('flags_Flag_Base')

    for (let i = 1; i <= 12; i += 1) {

      const clonedMesh = mesh.clone()
      clonedMesh.geometry = mesh.geometry.clone()
      clonedMesh.matrixAutoUpdate = true
      clonedMesh.name = `${mesh.name}_cloned_${i}`
      const stringNumber = i < 10 ? `0${i}` : i
      const object3D = game.getObject3D(`Flag_A_0${stringNumber}`)
      object3D.scale.set(1, 1, 1)
      object3D.rotation.y -= Math.PI / 2
      object3D.position.y += 0.5
      object3D.add(clonedMesh)
      const random = THREE.MathUtils.randInt(0, 8)
      this.flagMeshesOptions.push({
        mesh: clonedMesh,
        indexFrom: random,
        indexTo: random + 1,
        actualRotation: 1
      })

      // musime dat na nulu prvy influence, lebo nam to bude robit potom problemy
      if (clonedMesh.morphTargetInfluences) clonedMesh.morphTargetInfluences[0] = 0

      this.changeUVs(
        clonedMesh,
        flagsConfig.flagsToUse[i - 1].x,
        flagsConfig.flagsToUse[i - 1].y
      )

    }

    mesh.visible = false

  }

  /**
   * Zmena UV
   * @param mesh - Mesh na ktorom menime UVcka
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   */
  private changeUVs(mesh: THREE.Mesh, indexU: number, indexV: number): void {

    const { horizontalValue, verticalValue } = flagsConfig
    const uvAttribute = mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = uvAttribute.getX(i) + (horizontalValue * indexU)
      const v = uvAttribute.getY(i) + (verticalValue * indexV)

      uvAttribute.setXY(i, u, v)

    }

    uvAttribute.needsUpdate = true

  }

  /**
   * Hybanie vlajkami
   */
  private waveFlags(): void {

    let nullifyProgress = false
    this.flagMeshesOptions.forEach(({ mesh, indexFrom, indexTo, actualRotation }, index) => {

      const inIntro = disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.start])
      // optimalizacia, aby sa zbytocne vlajky nehybali, ktore nevidime
      if (
        !mesh.morphTargetInfluences ||
        (!inIntro && index < 6 && !disciplinePhasesManager.phaseShooting.isInEmotion)
      ) return

      mesh.morphTargetInfluences[indexFrom] = 1 - this.progress
      mesh.morphTargetInfluences[indexTo] = this.progress

      mesh.rotateY(this.flagMeshesOptions[index].actualRotation > 0 ?
        flagsConfig.rotationSpeed :
        -flagsConfig.rotationSpeed)


      if (this.progress >= 1) {

        mesh.morphTargetInfluences[indexFrom] = 0
        mesh.morphTargetInfluences[indexTo] = 1

        this.flagMeshesOptions[index].indexFrom += 1
        if (this.flagMeshesOptions[index].indexFrom >= 10) this.flagMeshesOptions[index].indexFrom = 0

        this.flagMeshesOptions[index].indexTo += 1
        if (this.flagMeshesOptions[index].indexTo >= 10) this.flagMeshesOptions[index].indexTo = 0


        const changeDir = Math.random() > flagsConfig.rotationChangeProbability

        if (changeDir || mesh.rotation.y > flagsConfig.rotationMax || mesh.rotation.y < flagsConfig.rotationMin) {

          this.flagMeshesOptions[index].actualRotation = actualRotation * -1

        }

        nullifyProgress = true

      }

    })

    if (nullifyProgress) this.progress = 0

    this.progress += (flagsConfig.updateSpeed / fpsManager.fpsLimit)

  }

  /**
   * Aktualizacia vlajok
   */
  public updateFlags(): void {

    if (!this.movingFlagsEnabled) return

    this.waveFlags()

    if (this.flagsLightMap) this.flagsLightMap.offset.x -= flagsConfig.lightMapSpeed

  }

  /**
   * Nastavenie vysvietenia zony terca pri trafeni
   */
  public setTargetMarkers(): void {

    for (let i = 1; i <= 11; i += 1) {

      const mesh = game.getMesh(`envDynamic_HitMarker_${i}`)
      mesh.matrixAutoUpdate = true

    }

  }

  /**
   * Zahratie vysvtietenia zony terca pri trafeni
   * @param index - Index zony, ktoru mame vysvietit
   */
  public playTargetMarker(index: number): void {

    // ak je index 0, znamena to, ze sme netrafili ziadnu zonu, tym padom nic nevysvecujeme
    if (index === 0) return

    this.stopTargetMarkers()

    const { tween } = gameConfig.targetMarkers
    const mesh = game.getMesh(`envDynamic_HitMarker_${index}`)

    this.lastIndexTargetMarkers = index

    this.targetMarkersTween = gsap.timeline()
      .to({}, {
        duration: tween.delay,
        onComplete: () => {

          mesh.visible = false
          this.targetMarkersBgMesh.visible = true

        }
      })
      .to({}, {
        duration: tween.duration,
        onComplete: () => {

          mesh.visible = true
          this.targetMarkersBgMesh.visible = false

        }
      })

  }

  /**
   * Zastavenie vysvietenia zony terca po trafeni
   */
  public stopTargetMarkers(): void {

    if (this.targetMarkersTween) this.targetMarkersTween.kill()

  }

  /**
   * Sprava oblakov
   */
  public manageSkyboxClouds() {

    if (!this.skyboxCloudsMesh || !this.movingSkyboxEnabled) return
    this.skyboxCloudsMesh.rotateY(-gameConfig.skyboxCloudsOffsetStep)

  }

  /**
   * Zmena textury pre digital display
   * @param toLongDistance - True, ak sa ma dat long distance typ
   */
  public changeTextureDigitalDisplay(toLongDistance: boolean) {

    // staci nam zmenit texturu na materialy jedneho meshu, aby to zasiahlo vsetko
    const digitalDisplayMesh = game.getMesh('envDynamic_Display_PTS')
    const digitalDisplayMaterial = digitalDisplayMesh.material as THREE.MeshBasicMaterial
    const textureName = toLongDistance ? TexturesNames.digitalDisplayLongDistance : TexturesNames.digitalDisplay
    const texture = game.texturesToUse.main.get(textureName)
    if (texture) digitalDisplayMaterial.map = texture

  }

  /**
   * Nastavenie viditelnosti tienov na teci
   * @param visible - Ak true, tak budu na terci, ak false, tak pod hracom
   */
  public setVisibilityShadowsOnTarget(visible: boolean): void {

    // zobrazovanie/nezobrazovanie tienov - na terci alebo pod hracom
    worldEnv.targetBgMesh.visible = visible
    game.shadowsManager.getPlane(0).visible = !visible

    // musime nasledovat rozne veci podla viditelnosti a kde su tiene
    const objectToFollow = visible ? worldEnv.targetBgMesh : player.athleteObject
    game.shadowsManager.setObjectToFollowWithLight(objectToFollow)

  }

}

export const worldEnv = new WorldEnv()
