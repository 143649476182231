import { DrawTypes } from '../types'
/**
 * Konfig pre natiahnutie
 */
export const drawConfig = {

  /** Typ natiahnutia */
  // type: DrawTypes.hold,
  type: DrawTypes.bar,

  /** Nastavenia pre typ s drzanim pre natiahnutie */
  holdType: {

    /** Pocet frameov na natiahnutie */
    framesForward: 20,

    /** O kolko frameov sa znici counter frameov na vratenie natiahnutia */
    framesPerReturn: 4

  },

  /** Nastavenia pre typ s barom pre natiahnutie */
  barType: {

    /** Hodnota, ako rychlo sa pohybuje bar, zatial linearne */
    addValuePerFrame: 4,

    /** Limity v bare pre farebnost, pricom stredna hodnota je ideal */
    barColorLimits: [70, 73, 76, 80, 84, 87, 90],

    /** Ideal v bare */
    barIdealValue: 80,

    /** Maximumi v bare */
    barMaxValue: 100

  },

  /** Kolko frameov bude freeze po starte, aj kvoli animacii nasadenia sipu */
  freezedFramesAfterStart: 50,

  /** Kolko frameov bude frreze po starte, ked nenabijam luk */
  freezedFramesAfterStartNoReload: 10

}