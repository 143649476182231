import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.spriteNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    subset: true,
    randomizeSprite: true,
    category: AudioCategories.sprite,
    version: 3,
    sprite: {
      'archery_hit_sound_var01': [
        0,
        676.0544217687075
      ],
      'archery_hit_sound_var012': [
        2000,
        961.0657596371883
      ],
      'archery_release_sound_var01': [
        4000,
        1286.757369614513
      ],
      'archery_release_sound_var012': [
        7000,
        1301.791383219955
      ],
      'archery_robin_hood_sound': [
        10000,
        865.2380952380945
      ]
    }
  },
  {
    files: [
      AudioNames.spriteAnnouncer
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    sprite: {
      'archery_announcer_0': [
        0,
        1421.4739229024945
      ],
      'archery_announcer_1': [
        3000,
        1033.1972789115644
      ],
      'archery_announcer_10': [
        6000,
        1971.5192743764173
      ],
      'archery_announcer_2': [
        9000,
        1234.444444444444
      ],
      'archery_announcer_3': [
        12000,
        874.5578231292512
      ],
      'archery_announcer_4': [
        14000,
        1300.0000000000007
      ],
      'archery_announcer_5': [
        17000,
        1199.9999999999993
      ],
      'archery_announcer_6': [
        20000,
        1331.2471655328793
      ],
      'archery_announcer_7': [
        23000,
        1300.9070294784592
      ],
      'archery_announcer_8': [
        26000,
        1255.215419501134
      ],
      'archery_announcer_9': [
        29000,
        1409.1609977324247
      ]
    }
  },
  {
    files: [
      AudioNames.spriteClaps
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    sprite: {
      'cheer_and_clap': [
        0,
        4429.07029478458
      ],
      'only_clap': [
        6000,
        4953.469387755103
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'VO_archery_10_points_var01': [
        0,
        2238.503401360544
      ],
      'VO_archery_10_points_var012': [
        4000,
        3200.9523809523807
      ],
      'VO_archery_8_points_var01': [
        9000,
        3654.3990929705215
      ],
      'VO_archery_8_points_var012': [
        14000,
        3250.9523809523807
      ],
      'VO_archery_9_points_var01': [
        19000,
        2932.3356009070308
      ],
      'VO_archery_9_points_var012': [
        23000,
        2137.5056689342387
      ],
      'VO_archery_rank_1': [
        27000,
        6763.42403628118
      ],
      'VO_archery_rank_2_3': [
        35000,
        7559.387755102037
      ],
      'VO_archery_rank_4_5': [
        44000,
        6341.655328798182
      ],
      'VO_archery_rank_6': [
        52000,
        7025.102040816329
      ],
      'VO_archery_robin_hood': [
        61000,
        6130.090702947839
      ]
    }
  }
]