import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(33.19183349609375, 19.12055206298828, 101.48838806152344),
      endPosition: new THREE.Vector3(10.275594711303711, 7.324023246765137, 102.70872497558594),
      startRotation: new THREE.Vector3(1.5491924590128874, 0.025651259759608084, -0.8701946576119731),
      endRotation: new THREE.Vector3(1.4300421099503122, 0.04420063292040375, -0.302113501442735),
      duration: 5,
      notSkippableFrames: 20,
      fov: 62.5 - 12
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(14.950159072875977, 1.4051148891448975, 84.97856140136719),
      endPosition: new THREE.Vector3(4.679584503173828, 1.34593665599823, 81.05238342285156),
      startRotation: new THREE.Vector3(1.5310666732189409, 0.02357741003679916, -0.5353169568469427),
      endRotation: new THREE.Vector3(1.5501648747139893, 0.006476686073820437, -0.304156945922212),
      duration: 3,
      notSkippableFrames: 20,
      fov: 50.7 - 6
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(20.672060012817383, 5.88576889038086, 117.11141204833984),
      endPosition: new THREE.Vector3(24.31209945678711, 8.488192558288574, 99.2518310546875),
      startRotation: new THREE.Vector3(1.709798533283256, 0.02823006889741472, 0.19909446116305493),
      endRotation: new THREE.Vector3(1.709798533283256, 0.02823006889741472, 0.19909446116305493),
      fov: 70.7,
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(1.1436511278152466, 1.0832312107086182, 72.4385070800781),
        endPosition: new THREE.Vector3(1.001293659210205, 1.3011038303375244, 69.93468475341797),
        startRotation: new THREE.Vector3(1.605138504069091, -0.0019179612356946384, -0.05588982909064788),
        endRotation: new THREE.Vector3(1.5711614124806637, -0.00001526660119359108, -0.05331056895874125),
        duration: 3,
        fov: 41.9,
        fovEnd: 53.9
      },
      {
        startPosition: new THREE.Vector3(4.507824897766113, 1.6231446266174316, 69.5411376953125),
        endPosition: new THREE.Vector3(2.990687131881714, 1.6231446266174316, 71.12084197998047),
        startRotation: new THREE.Vector3(1.4944717803645526, 0.10848256129370351, -0.9557162034652593),
        endRotation: new THREE.Vector3(1.4836111473433253, 0.06102032167038022, -0.6090130006974677),
        duration: 3,
        fov: 70.7
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(-2, 2, 3),
      endPosition: new THREE.Vector3(2, 2, 3),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}
