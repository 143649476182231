import type { PlayerTypes } from '.'
import type { THREE } from '@powerplay/core-minigames'

/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  athlete = 'athlete',
  env = 'env',
  trackLines = 'trackLines',
  envDynamic = 'envDynamic',
  flags = 'flags',
  skybox = 'SkyboxDay',
  cameraBoxes = 'cameraBoxes'

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {

  athleteRacePrefix = 'athlete_race_',
  athleteRaceWhiteMan = 'male/athlete_race_white',
  athleteRaceMulattoMan = 'male/athlete_race_mulatto',
  athleteRaceBlackMan = 'male/athlete_race_black',
  athleteRaceWhiteWoman = 'female/athlete_race_white',
  athleteRaceMulattoWoman = 'female/athlete_race_mulatto',
  athleteRaceBlackWoman = 'female/athlete_race_black',
  athleteClothes = 'athlete_clothes',
  athleteClothesOpponent1 = 'athlete_clothes_opponent_1_v',
  athleteEquipment = 'athleteEquipment',
  athleteEquipmentOpponent1 = 'athleteEquipment_opponent_1',
  athleteHair = 'athleteHair',
  audience = 'audience512v',
  colorGradient = 'ColorGradientAds2048v',
  colorGradientVshosting = 'ColorGradientAdsVshosting2048',
  concrete = 'CleanConcrete512',
  ground = 'GroundTexture512',
  lights = 'Emissive512',
  metal = 'MetalDetail512',
  panel = 'Panel512',
  seats = 'Seats512v',
  stairs = 'Stairs512',
  texts = 'SummerGamesText1024',
  trapNet = 'TrapNet512',
  grassUncut = 'GrassUncut512',
  numbers = 'NumbersTemp512',
  paving = 'Paving512',
  trees = 'Trees1024v',
  glass = 'GlassPanel512',
  lattice = 'Lattice512',
  ppsLogo = 'PPSLogo1024v',
  skybox = 'Skybox1024',
  skyboxMountain = 'SkyboxMountain1024',
  skyboxClouds = 'SkyboxClouds1024',
  skyboxBuilding1 = 'SkyboxBuildings1_512',
  skyboxBuilding2 = 'SkyboxBuildings2_512',
  envLightmap = 'EnvLightmap2048v',
  flagGradient = 'FlagGradient256',
  npc = 'NPC512',
  flags = 'FlagsImages2048',
  screens = 'Screens1024v',
  screensVshosting = 'ScreensVshosting1024',
  digitalDisplay = 'DigitalDisplay512v',
  digitalDisplayLongDistance = 'DigitalDisplay256v',
  targetMarkers = 'targetMarkers256v',
  target = 'Target256v'

}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {

  athlete = 'athlete',
  athleteEquipment = 'athleteEquipment',
  athleteHair = 'athleteHair',
  athleteEquipmentOpponent1 = 'athleteEquipmentOpponent1',
  athleteOpponentPrefix = 'athlete_opponent_',
  athleteOpponent1 = 'athlete_opponent_1',
  athleteHairOpponent1 = 'athleteHairOpponent1',
  audience = 'audience',
  colorGradient = 'colorGradient',
  concrete = 'concrete',
  ground = 'ground',
  lights = 'emissive512',
  metal = 'metal',
  metalWithoutLM = 'metalWithoutLM',
  panel = 'panel',
  seats = 'seats',
  stairs = 'stairs',
  texts = 'texts',
  dots = 'dots',
  grassUncut = 'grassUncut',
  numbers = 'numbers',
  paving = 'paving',
  trees = 'trees',
  glass = 'glass',
  lattice = 'lattice',
  onlyVertexColors = 'onlyVertexColors',
  npc = 'npc',
  flags = 'flags',
  screens = 'screens',
  ppsLogo = 'ppsLogo',
  digitalDisplay = 'digitalDisplay',
  targetMarkers = 'targetMarkers',
  targetMarkersGlow = 'targetMarkersGlow',
  target = 'target',
  skybox = 'Skybox',
  skyboxMontain = 'skyboxMontain',
  skyboxClouds = 'skyboxClouds',
  skyboxBuilding1 = 'skyboxBuilding1',
  skyboxBuilding2 = 'skyboxBuilding2',

}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {

  idle = 'idle',
  idle1 = 'idle 1',
  idle2 = 'idle 2',
  prepare1 = 'prepare 1',
  prepare2 = 'prepare 2',
  loading = 'loading',
  drawing = 'drawing',
  shoot = 'shoot',
  happy = 'happy 1',
  happy2 = 'happy 2',
  happy3 = 'happy 4',
  sad = 'sad',
  afterEmotion = 'after emotion'

}

/** Mena vsetkych animacii luku */
export enum BowAnimationsNames {

  idle = 'bow idle',
  drawing = 'bow drawing',
  shoot = 'bow shoot',

}

/** Typy verzii minihier */
export enum MinigameVersionTypes {

  a = 'A',
  b = 'B',
  c = 'C',
  none = 'none'

}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  nothing: unknown,
  type: MinigameVersionTypes

}

/** Vnutorne data pre obrazovku so skore */
export interface ScoreDisplayPanelInnerData {

  mesh: THREE.Mesh,
  originalUVs: THREE.BufferAttribute | THREE.InterleavedBufferAttribute

}

/** Data pre obrazovku so skore */
export type ScoreDisplayPanelData = {[ key in PlayerTypes]: ScoreDisplayPanelInnerData[]}
