import store from '@/store'
import {
  PlayerStates,
  type DisciplinePhaseManager
} from '../../types'
import {
  cameraManager,
  MobileDetector,
  modes,
  corePhasesManager
} from '@powerplay/core-minigames'
import {
  cameraSpecialConfig,
  drawConfig,
  gameConfig,
  trainingConfig
} from '@/app/config'
import { player } from '@/app/entities/athlete/player'
import { timeLimitManager } from '@/app/TimeLimitManager'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { wind } from '@/app/entities/athlete/Wind'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { aimingDirectionManager } from '../AimPhase/AimingDirectionManager'

/**
 * Trieda fazy pre natiahnutie - s drzanim pre natiahnutie
 */
export class DrawPhaseWithHold implements DisciplinePhaseManager {

  /** Ci je tato faza aktivna */
  public isActive = false

  /** Ci je aktivne natahovanie */
  public activeDrawForward = false

  /** Ci je aktivne vratenie sa do povodneho stavu */
  private activeDrawReturn = false

  /** Aktualna hodnota pocitadla frameov pre natiahnutie */
  public actualFrameForward = 0

  /** Pocitadlo frameov */
  private frameCounter = 0

  /** Ci uz skoncila tato faza */
  private ended = false

  /** podmienky pre coundown a wind */
  private conditions = {
    countdownActive: true,
    windActive: true,
    windChanging: true,
    showWind: true
  }

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('draw phase started')
    this.isActive = true
    if (modes.isTutorial()) {

      this.conditions = {
        countdownActive: tutorialFlow.countdownActive,
        windActive: tutorialFlow.windActive,
        windChanging: true,
        showWind: tutorialFlow.windActive
      }

    }
    if (modes.isTrainingMode()) {

      this.conditions = {
        countdownActive: true,
        windActive: trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].active ?? true,
        windChanging: trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].changing ?? true,
        showWind: true
      }

    }
    wind.isActive = this.conditions.windActive
    wind.isChanging = this.conditions.windChanging

  }

  /**
   * Zobrazenie mobilnych buttonov
   */
  public showMobileButtons(): void {

    if (!MobileDetector.isMobile()) return

    store.commit('ActionButtonState/SET_SHOW_JOYSTICK', true)
    startPhaseStateManager.enableInputs(true)
    store.commit('InputsState/SET_DISABLED', false)

  }

  /**
   * Kontrola a riesenie inputov
   * @param pressStart - True, ak bol input aktivovany
   * @param isTouch - ci input je touch na mobile
   */
  public handleInputs(pressStart: boolean, isTouch = false): void {

    if (MobileDetector.isMobile() && !isTouch) return
    // aby neboli inputy hned po intre
    if (this.frameCounter < this.waitingFrames() || !this.isActive) return

    // ak mame zacat natahovat, tj este nenatahujeme
    if (pressStart && !this.activeDrawForward && !this.activeDrawReturn) {

      console.log('handle inputs - START')
      this.activeDrawForward = true
      this.activeDrawReturn = false

    }

    if (!pressStart && !this.activeDrawReturn) {

      console.log('handle inputs - STOP')
      this.activeDrawReturn = true
      this.activeDrawForward = false

    }

    // startPhaseStateManager.hideTextMessage()

  }

  /**
   * Proces natiahnutia
   */
  private drawForward(): void {

    if (!this.activeDrawForward) return

    this.actualFrameForward += 1
    console.log('draw forward', this.actualFrameForward)
    if (this.actualFrameForward >= drawConfig.holdType.framesForward) {

      this.finishPhase(false)

    }

  }

  /**
   * Proces vratenia sa z natiahnutia
   */
  private drawReturn(): void {

    if (!this.activeDrawReturn) return

    this.actualFrameForward -= drawConfig.holdType.framesPerReturn
    console.log('draw return', this.actualFrameForward)
    if (this.actualFrameForward <= 0) {

      this.actualFrameForward = 0
      this.activeDrawReturn = false

    }

  }

  /**
   * Vypocitanie bodu medzi dvomi bodmi podla percenta
   * @param pointA - bod A
   * @param pointB - bod B
   * @param percentage - % medzi bodmi
   * @returns Bod medzi A a B
   */
  private getPointInBetweenByPerc(
    pointA: THREE.Vector3,
    pointB: THREE.Vector3,
    percentage: number
  ): THREE.Vector3 {

    let dir = pointB.clone().sub(pointA)
    const len = dir.length()
    dir = dir.normalize().multiplyScalar(len * percentage)
    return pointA.clone().add(dir)

  }

  /**
   * Zmena kamery pri natahovani, prip vracani naspat
   */
  private changeCamera(): void {

    const percent = this.actualFrameForward / drawConfig.holdType.framesForward
    const configFrom = gameConfig.cameraConfig
    const configTo = cameraSpecialConfig.cameraDraw

    cameraManager.changeIdeals(
      this.getPointInBetweenByPerc(configFrom.idealOffset, configTo.idealOffset, percent),
      this.getPointInBetweenByPerc(configFrom.idealLookAt, configTo.idealLookAt, percent),
      undefined,
      1
    )

  }

  /**
   * Vratenie percent fazy, aby sme vedeli, kde v animacii akurat mame byt
   * @returns Percento fazy
   */
  public getPhasePercent(): number {

    let percent = this.actualFrameForward / drawConfig.holdType.framesForward
    if (percent > 1) percent = 1
    return percent

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.drawForward()

    if (this.ended) return

    this.drawReturn()
    this.changeCamera()
    this.frameCounter += 1

    if (this.frameCounter === this.waitingFrames()) {

      tutorialFlow.nextAttempt()

      if (modes.isTutorial()) {

        this.conditions.countdownActive = tutorialFlow.countdownActive
        this.conditions.showWind = tutorialFlow.windActive

      }
      timeLimitManager.setActive(this.conditions.countdownActive)
      player.setState(PlayerStates.drawing)
      this.showMobileButtons()

      store.commit(
        'WindState/SET_SHOW_WIND',
        this.conditions.showWind
      )
      store.commit(
        'WindState/SET_SHOW_COUNTDOWN',
        this.conditions.countdownActive
      )

    }

  }

  /**
   * Vypocet poctu freeze fames pred povolenim natiahnutia
   */
  private waitingFrames() {

    if (modes.isTrainingMode()) {

      return corePhasesManager.disciplineActualAttempt !== 1 ? drawConfig.freezedFramesAfterStartNoReload :
        drawConfig.freezedFramesAfterStart

    }
    return !disciplinePhasesManager.isFirstAttemptInGroup() ?
      drawConfig.freezedFramesAfterStartNoReload :
      drawConfig.freezedFramesAfterStart

  }

  /**
   * Ukoncene fazy
   * @param forced - True, ak sa vynutil koniec a preskakuje sa faza
   */
  public finishPhase = (forced: boolean): void => {

    if (this.ended) return

    store.commit(
      'WindState/SET_SHOW_WIND_CENTER',
      this.conditions.showWind
    )
    store.commit('MovementButtonsState/SET_ACTIVE', true)
    this.ended = true
    this.isActive = false
    console.warn('draw phase ended')

    // aby sme mohli nizsie riesit mieritko, tak si musime najskor vytvorit aiming point, ak este nie je vytvoreny
    aimingDirectionManager.createAimingPoint()
    // musime to aktualizovat tu, aby sa mieritko zobrazilo na spravnej pozicii bez prebliku
    aimingDirectionManager.updateAimingPosition()

    if (forced) {

      this.actualFrameForward = 0
      this.changeCamera()
      return

    }

    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.isActive = false
    this.activeDrawForward = false
    this.activeDrawReturn = false
    this.actualFrameForward = 0
    this.frameCounter = 0
    this.ended = false
    store.commit('MovementButtonsState/SET_ACTIVE', false)
    this.conditions = {
      countdownActive: true,
      windActive: true,
      windChanging: true,
      showWind: true
    }

  }

}
