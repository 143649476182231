<template>
  <section
    class="tutorial"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <tutorial-tasks
      :tasks="tasks"
    />
    <tutorial-overlay
      v-if="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="tutorialState.anne.isRight"
      :instructor-name="tutorialState.anne.instructorName"
    />
    <tutorial-under-menu v-if="tutorialState.showUnderMenu" />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="typeWrite"
      :type-write="typeWrite"
      :class="{ offsetBot: tutorialState.tutorialMessage.offset }"
      :header-text="$t(tutorialState.anne.instructorNameText)"
      :text="getMessageText(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="tutorialState.tutorialMessage.yellowText"
      :force-hide-white-text="tutorialState.tutorialMessage.yellowTextSpecial"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile.show"
      style="pointer-events: auto"
      img-url="https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/archery/ui/tutorial/mobile.jpg"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    />
    <instruction-box
      v-if="tutorialState.showInstructionBox"
      style="bottom: 20%"
      :right-content-text="$t(tutorialState.instructionBoxText)"
    />
    <div class="start-stats-box-tutorial">
      <arrow-animation
        v-if="tutorialState.showTime"
        position="top"
        :style="{position: 'absolute', left: '50%', top: '2%', height: '57px'}"
      />
      <start-countdown-time
        v-if="tutorialState.showTime"
        text="Time to start"
        :time="15"
        glow
        :style="{position: 'absolute', left: '50%', top: '1%', margin: '0.25em 0px'}"
      />
      <arrow-animation
        v-if="tutorialState.showWind"
        position="right"
        :style="{
          position: 'absolute', right: '2%', top: '17%', transformOrigin: 'top right', width: '251px', height: '121px'
        }"
      />
      <wind-box
        v-if="tutorialState.showWind"
        :wind-value="String(wind.value)"
        glow
        :rotate-value="rotateValue"
      />
    </div>
  </section>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  InstructionBox,
  StartCountdownTime,
  ArrowAnimation,
  WindBox,
  TutorialHandSettings
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import {
  requestManager,
  settings,
  tutorialManager
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import TutorialUnderMenu from '@/components/Tutorial/TutorialUnderMenu.vue'
import { pathAssets } from '@/globals/globalvariables'


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialUnderMenu,
    InstructionBox,
    StartCountdownTime,
    ArrowAnimation,
    WindBox,
    TutorialHandSettings
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      // TODO: toto podla use case
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      tutorialState: 'TutorialState/getTutorialState',
      tutorialObjectives: 'TutorialState/getTutorialObjectives',
      typeWrite: 'TutorialCoreState/getTypeWriter',
      wind: 'WindState/getWindState',
    }),
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    },
    rotateValue() {

      return (Number(this.wind.direction) + 2) * 15 + 60

    }
  },

  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    }
  },
  methods: {
    setTypewriteToInactive() {

      this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', this.gameSettingsState.graphicsSettings)
      settings.update('sounds', this.gameSettingsState.volume ? 1 : 0)
      settings.update(
        'qualityAuto',
        this.gameSettingsState.graphicsAuto ? 1 : 0
      )
      settings.update('isLeft', this.gameSettingsState.isLeft ? 1 : 0)

    },
    updateTasks() {

      this.tasks = this.tutorialObjectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: `${this.$t(objective.name).replace('[number]', '30') } ${this.tutorialState.resultPoints}/30`
        }

      })

    },
    getMessageText(text: string): string {

      const { disciplineID } = requestManager

      if (text === `tutorialText${disciplineID}-2` || text === `tutorialText${disciplineID}-3`) {

        return this.$t(text).replace('[number]', '15')

      }

      return this.$t(text)

    }
  }
})
</script>

<style lang="less">
.tutorial {

    &.mobile-overlay {
      .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
          position: fixed;
      }
    }

  .offsetBot {
    bottom: 40%;
  }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
  .start-stats-box-tutorial {
    height: 100%;
    position: absolute;
    width: 100%;

    .wind-box {
      position: absolute;
      right: 1%;
      top: 17%;
      transform-origin: top right;
    }
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 130px;
            left: 130px;
            right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>
