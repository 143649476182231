import store from '@/store'
// import { LoadingState } from '@powerplay/core-minigames-ui-ssm'
import {
  playersManager,
  settings,
  SettingsTypes,
  timeManager,
  type PlayerInfoForTable,
  corePhasesManager
} from '@powerplay/core-minigames'
import type { MiniTableRowData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/MiniTable/MiniTable.vue'
import { player } from '../entities/athlete/player'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  private store = store

  public getFpsStarted(): boolean {

    return this.store.getters['FpsLookerState/getFpsStarted']

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    this.store.commit('FpsLookerState/SET_FPS_STATE', fpsData)

  }

  public allowDirectionState(): void {

    this.store.commit('DirectionsState/SET_STATE', {
      show: true
    })

  }

  /*
   * public getLoadingState (): LoadingState {
   *
   *  return this.store.getters['LoadingState/getLoadingState']
   *
   * }
   */

  public setLoadingState(loadingState: unknown): void {

    this.store.commit('LoadingState/SET_STATE', loadingState)

  }

  public setBeforeGameStartPhase(): void {

    this.store.commit('GameSettingsState/SET_STATE', {
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType)
    })

  }

  public setUpdateTimeState(): void {

    store.commit('TimeState/SET_STATE', {
      time: timeManager.getGameTimeInFormat(1)
    })

  }

  public hideSpeedVisibility(): void {

    this.store.commit('SpeedmeterState/SET_VISIBILITY', false)

  }

  public updateSpeedData(speedData: SpeedData): void {

    this.store.commit('SpeedmeterState/SET_STATE', speedData)

  }

  /**
   * Vytiahnutie dat pre minitable
   * @param playerPoints - body posledneho pokusu hraca
   */
  public static getDataForMiniTable(playerPoints = 0) {

    const rowsData = [] as MiniTableRowData[]

    const standings = playersManager.getStandings()
    const playerPosition = standings.filter((value) => value.playable)[0].position

    let indexes = []

    if (playerPosition < 4) {

      indexes = [0, 1, 2, 3]

    } else if (playerPosition === standings.length) {

      indexes = [0, playerPosition - 3, playerPosition - 2, playerPosition - 1]

    } else {

      indexes = [0, playerPosition - 2, playerPosition - 1, playerPosition]

    }

    indexes.forEach((index) => {


      if (standings[index]) {

        const minitableRowData = this.getMiniTableDataFromPlayerInfo(standings[index], playerPoints)
        if (minitableRowData.textRight !== '') rowsData.push(minitableRowData)

      }


    })

    return rowsData

  }

  /**
   * Namapovanie dat pre minitable
   * @param playerInfo - playerInfo pre mapovanie
   * @param playerPoints - body posledneho pokusu hraca
   * @returns MiniTableRowData
   */
  private static getMiniTableDataFromPlayerInfo(playerInfo: PlayerInfoForTable, playerPoints: number) {

    const lastAttempt = playerInfo.uuid === player.uuid ?
      `+${ playerPoints}` :
      `+${
        playersManager.getPlayerById(playerInfo.uuid)?.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main
      }` || ''
    return {
      position: playerInfo.position,
      country: playerInfo.country,
      countryString: playerInfo.countryString,
      player: {
        name: playerInfo.name,
        isPlayer: playerInfo.playable
      },
      textRight: String(playerInfo.result),
      lastAttempt: corePhasesManager.disciplineActualAttempt === 1 ? '' : lastAttempt
    } as MiniTableRowData

  }

}

export const stateManager = new StateManager()
