import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  minigameConfig,
  modes,
  corePhasesManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig
} from '../../config'
import {
  ModelsNames,
  PlayerAnimationsNames,
  PlayerStates
} from '../../types'
import { AthleteAnimationManager } from '.'
import { Bow } from '../equipment/Bow'
import { Arrow } from '../equipment/Arrow'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Stav hraca */
  private state = PlayerStates.idle

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** Cas v cieli */
  public finalTime = minigameConfig.dnfValue

  /** luk */
  public bow = new Bow(this)

  /** sip */
  public arrow = new Arrow(this)

  /** Objekt pre vlasy */
  protected hair!: THREE.Mesh

  /** Objekt pre cop */
  protected hairTail!: THREE.Mesh

  /** Prefix pre pohlavie a 3d objekty */
  protected prefixSex = ''

  /** Typ vlasov */
  protected hairType = 1

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Nastavenie stavu atleta
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setState(state: PlayerStates, maxRandomDelay = 0): void {

    const random = THREE.MathUtils.randFloat(0, maxRandomDelay)

    if (random === 0) {

      this.state = state

    } else {

      gsap.to({}, {
        duration: random,
        callbackScope: this,
        onComplete: () => {

          this.state = state
          console.log('state random', state, this.uuid, random)

        }
      })

    }

  }

  /**
   * Zistenie ci je aktualne dany stav
   * @param state -stav
   * @returns True, ak ano
   */
  public isState(state: PlayerStates): boolean {

    return this.state === state

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param athleteName - Meno alteta
   */
  public create(athleteName: string, position = gameConfig.startPosition, rotation = gameConfig.startRotation): void {

    this.playable = playersManager.getPlayerById(this.uuid)?.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    // zmenime animationsConfig inak pre hraca, inak pre supera, ale musime si ho naklonovat, aby sa to nezlucilo
    const animationsConfigCopy = JSON.parse(JSON.stringify(animationsConfig))
    let animToStartWith = !this.playable ?
      PlayerAnimationsNames.idle1 :
      PlayerAnimationsNames.idle2
    if (modes.isTrainingMode()) {

      animToStartWith = PlayerAnimationsNames.prepare1

    }
    animationsConfigCopy[animToStartWith].starting = true

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfigCopy,
      game.animations.get(ModelsNames.athlete),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()
    this.animationsManager.changeTo(animToStartWith)

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.rotation.set(rotation.x, rotation.y, rotation.z)
    this.athleteObject.name = athleteName

    // tiene
    if (!this.playable) return
    game.shadowsManager.attachPlaneToObject(this.athleteObject)

  }

  /**
   * Vytvorenie luku a sipu
   */
  public createTools(): void {

    this.bow.create()
    this.arrow.create()
    if (modes.isTrainingMode()) {

      this.bow.setVisibility(false)
      this.arrow.setVisibility(false)

    }

  }

  /**
   * Odstartovanie hraca
   */
  public start(): void {

    // TODO

    /*
     * console.log('odstartoval atlet', this.uuid)
     * this.setState(PlayerStates.setToStartRun)
     * this.speedManager.setActive(true)
     * dame rovno prvy input
     * this.velocityManager.handleInputs()
     * if (this.playable) store.commit('GamePhaseState/SET_SHOW', true)
     */

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    // animacie aktualizujeme stale, aj kvoli intru
    this.athleteAnimationManager.update()

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)
    this.bow.update(delta)
    this.arrow.update()

  }

  /**
   * reset atleta
   */
  public reset(): void {

    this.state = PlayerStates.idle
    this.animationsManager.resetAll()
    this.athleteAnimationManager.reset()
    this.finalTime = 0
    this.bow.reset()
    this.animationsManager.resetSpeed()
    if (modes.isTrainingMode()) {

      this.bow.setVisibility(false)
      this.arrow.setVisibility(false)
      if (corePhasesManager.disciplineActualAttempt !== 0) {

        this.animationsManager.changeToPaused(PlayerAnimationsNames.drawing)

      } else {

        this.animationsManager.changeTo(PlayerAnimationsNames.prepare1)

      }

    }

  }

}
