<template>
  <div class="start-stats-box">
    <start-countdown-time
      v-if="wind.showCountdown"
      text="Time to start"
      :time="timer"
      :style="{position: 'absolute', left: '50%', top: '1%',
               margin: '0.25em 0px'}"
    />
    <wind-box
      v-if="wind.showWind"
      :wind-value="String(wind.value)"
      :rotate-value="rotateValue"
    />
    <wind-component
      v-if="wind.showWindCenter"
      :wind-value="String(wind.value)"
      :rotate-value="rotateValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  StartCountdownTime,
  WindBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import WindComponent from './WindComponent.vue'

export default defineComponent({
  name: 'StartStatsBox',
  components: {
    StartCountdownTime,
    WindComponent,
    WindBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      timer: 0
    }

  },
  computed: {
    ...mapGetters({
      wind: 'WindState/getWindState',
      uiState: 'UiState/getUiState',
      startTimer: 'StartPhaseState/getStartTimer'
    }),
    rotateValue() {

      return (Number(this.wind.direction) + 2) * 15 + 60

    }
  },
  watch: {
    startTimer: {
      immediate: true,
      handler(value: number) {

        if (Math.ceil(value) !== this.timer) {

          this.timer = Math.ceil(value)

        }

      }
    }
  }
})
</script>

<style lang="less" scoped>
  .start-stats-box {
    height: 100%;
    position: absolute;
    width: 100%;

    .wind-box {
      position: absolute;
      right: 1%;
      top: 17%;
      transform-origin: top right;
    }
  }
</style>
