import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre specialne stav
 */
export const cameraSpecialConfig = {

  /** priblizena kamera pre draw fazu */
  cameraDraw: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(-0.5, 1.6, -0.5),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  /** kamera pre let sipu */
  cameraArrow: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0.2, -0.2, -1.5),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 0, 0),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  cameraArrowInTarget: {
    /*
     * ako daleko od sipu ma byt kamera na konci tweenu
     * typ THREE.Vector3(0, 0, 0)
     */
    idealOffset: new THREE.Vector3(0.4, 0, -1.2),

    // cas tweenu
    duration: 2
  }

}
