import {
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  fpsManager,
  cameraManager
} from '@powerplay/core-minigames'
import {
  DisciplinePhases,
  DrawTypes,
  AudioNames,
  AudioGroups
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/athlete/player'
import { DrawPhaseWithHold } from './DrawPhase/DrawPhaseWithHold'
import store from '@/store'
import { endManager } from '../EndManager'
import { trainingTasks } from '../modes/training'
import {
  drawConfig,
  gameConfig
} from '../config'
import { AimPhase } from './AimPhase/AimPhase'
import { DrawPhaseWithBar } from './DrawPhase/DrawPhaseWithBar'
import { ShootingPhase } from './ShootingPhase/ShootingPhase'
import { aimingDirectionManager } from './AimPhase/AimingDirectionManager'
import { wind } from '../entities/athlete/Wind'
import { timeLimitManager } from '../TimeLimitManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** Faza startu */
  public phaseStart!: StartPhaseManager

  /** Faza natiahnutia */
  public phaseDraw!: DrawPhaseWithBar | DrawPhaseWithHold

  /** Faza mierenia */
  public phaseAim!: AimPhase

  /** Faza strielania */
  public phaseShooting!: ShootingPhase

  /** faza konca */
  public phaseFinish!: FinishPhaseManager

  /** Callback pre pripravenie hry tak, aby isiel dalsi pokus */
  private callbackPrepareGameForNextAttempt!: () => unknown

  /**
   * Vytvorenie a nastavenie veci
   * @param callbackPrepareGameForNextAttempt - Callback pre pripravenie hry na dalsi pokus
   */
  public create(callbackPrepareGameForNextAttempt: () => unknown): void {

    this.callbackPrepareGameForNextAttempt = callbackPrepareGameForNextAttempt
    this.createAllPhases()
    aimingDirectionManager.init()

  }

  /**
   * co sa stane po fazy natiahnutia
   */
  private afterDrawPhase(): void {

    this.startDisciplinePhase(DisciplinePhases.aim)

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseStart = new StartPhaseManager(() => {

      this.startDisciplinePhase(DisciplinePhases.draw)
      // timeManager.setActive(TimesTypes.game, true)

    })

    if (drawConfig.type === DrawTypes.hold) {

      this.phaseDraw = new DrawPhaseWithHold(() => {

        this.afterDrawPhase()

      })

    } else {

      this.phaseDraw = new DrawPhaseWithBar(() => {

        this.afterDrawPhase()

      })

    }

    this.phaseAim = new AimPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.shooting)

    })

    this.phaseShooting = new ShootingPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseFinish = new FinishPhaseManager(() => {

      console.log('dispatch end')

      store.commit('WaitingState/SET_STATE', {
        isWaiting: true
      })

      if (corePhasesManager.disciplineActualAttempt >= 1) {

        store.commit('WaitingState/SET_STATE', {
          isWaiting: false
        })

        this.callbackPrepareGameForNextAttempt()

      }

      window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase

    if (phase === DisciplinePhases.start) this.phaseStart.startPhase()
    if (phase === DisciplinePhases.draw) this.phaseDraw.startPhase()
    if (phase === DisciplinePhases.aim) this.phaseAim.startPhase()
    if (phase === DisciplinePhases.shooting) this.phaseShooting.startPhase()
    if (phase === DisciplinePhases.finish) this.phaseFinish.startPhase()

  }

  /**
   * Ukoncenie aktualnej fazy
   * @param forced - Ci je forced ukoncenie
   */
  public finishActualPhase(forced: boolean): void {

    if (this.actualPhase === DisciplinePhases.start) this.phaseStart.finishPhase()
    if (this.actualPhase === DisciplinePhases.draw) this.phaseDraw.finishPhase(forced)
    if (this.actualPhase === DisciplinePhases.aim) this.phaseAim.finishPhase(forced)
    if (this.actualPhase === DisciplinePhases.shooting) this.phaseShooting.finishPhase()
    if (this.actualPhase === DisciplinePhases.finish) this.phaseFinish.finishPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    if (this.actualPhase === DisciplinePhases.start) this.phaseStart.update()
    if (this.actualPhase === DisciplinePhases.draw) this.phaseDraw.update()
    if (this.actualPhase === DisciplinePhases.aim) this.phaseAim.update()
    if (this.actualPhase === DisciplinePhases.shooting) this.phaseShooting.update()
    if (this.actualPhase === DisciplinePhases.finish) this.phaseFinish.update()

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    this.actualPhase = DisciplinePhases.end
    timeLimitManager.setActive(false)

    audioManager.stopAudioByGroup(AudioGroups.audience)
    audioManager.play(AudioNames.audienceHyped)

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    const isFinished = false

    // pri treningu musime dokoncit udaje
    trainingTasks.saveLastTasksValues()

    // posleme udaje
    endManager.sendLogEnd(player.uuid)
    endManager.sendSaveResult()

    // reset states
    await store.dispatch('clearStateAll')

    store.commit('WaitingState/SET_STATE', {
      isWaiting: true
    })
    if (!isFinished || corePhasesManager.firstInstructions) {

      store.commit('TrainingResultsState/SET_TRAIN_AGAIN_DISABLED', true)

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // v treningu musime spravit nejake upravy, aby vsetko fungovalo ako malo
    if (modes.isTrainingMode()) {

      if (gameConfig.cameraConfig.enabled) player.setGameCameraSettings(undefined, true)
      cameraManager.playTween(false)

    }


    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) {

      this.startStartPhase()
      return

    }

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0.01 : 0.2,
      onComplete: () => {

        this.startStartPhase()

      }
    })

  }

  /**
   * Spustenie start phase
   */
  private startStartPhase(): void {

    const phase = DisciplinePhases.start
    this.startDisciplinePhase(phase)

  }

  /**
   * resetovanie pokusu hry
   */
  public resetAttempt(): void {

    console.log('reseting attempt')

    this.phaseDraw.reset()
    this.phaseAim.reset()
    this.phaseShooting.reset()
    this.phaseFinish.reset()

    player.reset()
    wind.reset()
    timeLimitManager.reset()

    timeManager.reset()
    if (modes.isTrainingMode()) {

      player.bow.setVisibility(true)
      player.arrow.setVisibility(true)

    }

  }

  /**
   * Checker ci je prvy pokus v skupine
   * @returns ci je akurat ten prvy pokus
   */
  public isFirstAttemptInGroup(): boolean {

    return (corePhasesManager.disciplineActualAttempt - 1) % corePhasesManager.provisionalResultsFrequency === 0

  }

  /**
   * Checker tretieho pokusu
   * @returns ci je akurat treti pokus
   */
  public isThirdAttempt(): boolean {

    return corePhasesManager.disciplineActualAttempt === corePhasesManager.provisionalResultsFrequency

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    player.arrow.reset(true)
    this.createAllPhases()
    this.resetAttempt()
    if (modes.isTrainingMode()) {

      player.bow.setVisibility(false)
      player.arrow.setVisibility(false)

    }
    endManager.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
