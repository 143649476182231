<template>
  <div>
    <div
      class="training-layout"
    >
      <training-left-top-box
        v-if="trainingState.showLeftBoxes"
        :text="$t('highScore')"
        :points="highScore"
        :tasks="trainingState.tasks"
        :max-t-p-length="maxTPLength"
      />
    </div>
    <training-blue-box
      v-if="trainingState.showNewHighScore"
      style="position: absolute"
      :points="String(trainingState.newHighScore)"
      :text="$t('newHighScore')"
      :style="{width: 100 / scaleCoef + '%'}"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  TrainingBlueBox,
  TrainingLeftTopBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { trainingManager } from '@powerplay/core-minigames'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TrainingLeftTopBox,
    TrainingBlueBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      highScore: '0'
    }

  },
  computed: {
    ...mapGetters({
      trainingState: 'TrainingState/getTrainingState',
      uiState: 'UiState/getUiState',
    }),
    maxTPLength() {

      return trainingManager.getTpPerTask()?.toString().length

    }
  },
  watch: {
    'uiState.showTrainingLayout': {
      handler(value: boolean) {

        if (value) {

          this.highScore = String(trainingManager.bestScore)

        }

      }
    }
  }
})

</script>

<style lang="less">
.training-layout {
    position: absolute;
    left: 1%;
    top: 30px;
    width: 100%;
    height: 100%;
}
.training-blue-box {
    position: fixed;
}
</style>
