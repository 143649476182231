import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  THREE,
  errorManager,
  game,
  modes
} from '@powerplay/core-minigames'
import { DisciplinePhases } from '@/app/types'
import type { Athlete } from '../athlete'

/**
 * Trieda pre sip
 */
export class Arrow {

  /** Rig pre sip, resp vsetky kosti a skinned meshe v jednom */
  public arrowRig!: THREE.Object3D

  /** Hlavna kost sipu */
  public mainBone = new THREE.Bone()

  /** Mesh letiaceho sipu */
  public meshFlight = new THREE.Mesh()

  /** Mesh animacneho sipu */
  private meshAnimations = new THREE.Mesh()

  /** Skupina sipov, ktore su ponechane */
  private groupArrows = new THREE.Group()

  /** pomocny vektor */
  private helperWorldPosition = new THREE.Vector3()

  /** pomocny quaternion */
  private helperWorldQuaternion = new THREE.Quaternion()

  public constructor(private athlete: Athlete) {}

  /**
   * Vytvorenie sipu
   */
  public create(): void {

    // musime dat rig sipu prec z objektu hraca
    this.arrowRig = this.athlete.athleteObject.getObjectByName('arrow_rig') as THREE.Object3D
    game.scene.add(this.arrowRig)

    // skinned meshu musime vypnut frustumCulled, lebo sa to sprava, akoby bol na povodnej pozicii
    this.meshAnimations = this.arrowRig.getObjectByName('arrow') as THREE.Mesh
    if (this.meshAnimations !== undefined) this.meshAnimations.frustumCulled = false

    if (this.athlete.playable) {

      this.meshFlight = this.athlete.athleteObject.getObjectByName('athlete_arrow_2') as THREE.Mesh
      if (this.meshFlight === undefined) throw new Error(errorManager.showBox('Arrow2 mesh not found'))
      this.meshFlight.matrixAutoUpdate = true
      this.meshFlight.visible = false
      this.meshFlight.castShadow = true
      game.scene.add(this.meshFlight)

    }

    // nastavime hlavnu kost
    this.mainBone = this.arrowRig.getObjectByName('arrow_main') as THREE.Bone
    if (this.mainBone === undefined) throw new Error(errorManager.showBox('Arrow bone not found'))

    game.scene.add(this.groupArrows)

  }

  /**
   * Nastavenie viditelnosti
   * @param visibility - Viditelnost
   */
  public setVisibility(visibility: boolean): void {

    this.arrowRig.visible = visibility

  }

  /**
   * Nastavenie pozicie
   * @param position - Pozicia
   */
  public setFlightPosition(position: THREE.Vector3): void {

    this.meshFlight.position.set(position.x, position.y, position.z)

  }

  /**
   * Nastavenie rotacie
   * @param lookAt - Bod kam sa ma pozriet a narotovat
   */
  public setFlightRotation(lookAt: THREE.Vector3): void {

    this.meshFlight.lookAt(lookAt)

  }

  /**
   * Naklonovanie sipu na terci
   */
  public keepArrowOnTarget(): void {

    const arrow = this.meshFlight.clone()
    arrow.castShadow = true
    this.groupArrows.add(arrow)
    // TODO ked budeme potrebovat, mozeme vymazat vsetko v tejto grupe, napr reset trening

  }

  /**
   * Vymena viditelnosti letiaceho a animacneho sipu
   * @param toFlight - True, ak menime z animacneho na letiaci
   */
  public changeArrows(toFlight = true): void {

    this.meshAnimations.visible = !toFlight
    this.meshFlight.visible = toFlight

  }

  /**
   * Aktualizovanie sipu
   */
  public update(): void {

    if (disciplinePhasesManager.oneOfPhaseIsActual([DisciplinePhases.shooting])) return

    const arrowHelperBone = this.athlete.athleteObject.getObjectByName('arrow_helper')

    if (!this.mainBone || !arrowHelperBone) return

    arrowHelperBone.getWorldPosition(this.helperWorldPosition)
    arrowHelperBone.getWorldQuaternion(this.helperWorldQuaternion)

    this.mainBone.position.set(
      this.helperWorldPosition.x,
      this.helperWorldPosition.y,
      this.helperWorldPosition.z
    )

    this.mainBone.setRotationFromQuaternion(this.helperWorldQuaternion)

  }

  /**
   * reset sipu
   * @param forceClear - vycistenie mimo podmienky
   */
  public reset(forceClear = false): void {

    if ((disciplinePhasesManager.isFirstAttemptInGroup() && !modes.isTrainingMode()) || forceClear) {

      this.groupArrows.clear()

    }

  }

}
