import { ShootingTypes } from '../types'
import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre strielanie
 */
export const shootingConfig = {

  /*
   * Aky typ strielania mame
   * type: ShootingTypes.hold,
   * type: ShootingTypes.press,
   */
  type: ShootingTypes.bar,

  /** Nastavenia pre typ s barom pre natiahnutie */
  barType: {

    /** Hodnota, ako rychlo sa pohybuje bar, zatial linearne */
    addValuePerFrame: 4,

    /** Limity v bare pre farebnost, pricom stredna hodnota je ideal */
    barColorLimits: [35, 40, 45, 50, 55, 60, 65],

    /** Ideal v bare */
    barIdealValue: 50,

    /** Maximum v bare */
    barMaxValue: 100,

    /** Minimum v bare */
    barMinValue: 0

  },

  /** Nastavenia sipu */
  arrow: {

    /** Offset pre bezierovu krivku */
    bezier: {
      x: 0,
      y: 2,
      z: -35
    },

    /** Rychlost sipu v m/s */
    speed: {
      min: 40,
      max: 45
    },

    // Priemer sipu
    radius: 0.006849 / 2,

    /** dlzka sipu */
    arrowLength: 0.7,

    /** offset o kolko je pata sipu od spicky pri zapichnuti do terca */
    yOffsetArrowHeel: 0.04

  },

  // Veci suviasiace s tercom
  target: {

    // stred terca
    center: new THREE.Vector3(2.562, 1.84, 136.356),

    // zony terca
    zone: {

      // Priemer ciary zony terca
      lineDiameter: 0.00342857,

      // Priemer vnutornej
      innerDiameter: 0.0572000015,

    }

  },

  /** Casovy limit vo frameoch na vystrelenie */
  timeLimitShot: 15 * 30,

  // Pocet sekund, kolko sa caka v emocii
  waitInEmotion: 2.5,

  /** Ci je zapnuty debug na krivku */
  debugCurve: false,

  /** config pre robinhood pripad */
  robinHood: {
    /** vzdialenost pri ktorej stale berieme robina hooda POZOR: nesmie byt rovne alebo vacsie ako deviation*/
    distance: 0.01,

    /** aka je sanca, ze sa sip zapichne */
    chance: 0.5,

    /** kolko odskoci, ak sa nezapichne POZOR: nesmie byt rovne alebo mensie ako distance */
    deviation: 0.02

  }

}