import { Direction } from '../types'

/**
 * Konfig pre vietor
 */
export const windConfig = {

  /** Zmena vetra */
  change: {

    /** sanca na zmenu smeru */
    chanceToChangeDirection: 0.7,

    /** sanca na zmenu hodnoty */
    chanceToChangeValue: 0.7,

    /** Pocet frameov, za kolko sa zmeni vietor */
    frames: 10

  },

  /** Najnizsia hodnota vetra v m/s */
  minValue: 1,

  /** Najvyssia hodnota vetra v m/s */
  maxValue: 13,

  /** offsetova hodnota pre vychylenie strely v lubovolnom smere v m/s */
  offsetValue: 0.04,

  /** Debug veci */
  debug: {

    // ci je aktivny debug
    active: false,

    // debug smer
    direction: Direction.N,

    // debug hodnota
    value: 1

  }

}