import { player } from '../../entities/athlete/player'
import type { DisciplinePhaseManager } from '../../types'
import {
  corePhasesManager,
  fpsManager,
  modes
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'

/**
 * Trieda fazy pre dojazd v cieli (resp naburanie)
 */
export class FinishPhaseManager implements DisciplinePhaseManager {

  /** tween na ukoncenie fazy po animacii */
  private finishPhaseTween !: gsap.core.Tween

  /** ci faza skoncila */
  private ended = false

  /** ci je mozne skipnut */
  private skippable = true

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    fpsManager.pauseCounting()
    this.setFinishPhaseTween()

  }
  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    // zatial nic

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return

    if (modes.isTutorial()) {

      this.callbackEnd()
      return

    }
    if (
      modes.isTrainingMode() &&
      corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount
    ) {

      this.callbackEnd()
      return

    }

    this.ended = true

    if (this.finishPhaseTween) this.finishPhaseTween.kill()

    // resetujeme vsetky hlasky, aby nahodou neostali
    startPhaseStateManager.hideTextMessage(1)
    startPhaseStateManager.hideTextMessage(2)

    endManager.sendLogEnd(player.uuid)
    endManager.sendSaveResult()

    console.warn('finish phase ended')
    this.callbackEnd()

  }

  /**
   * sets tween to finish phase
   */
  public setFinishPhaseTween(): void {

    this.finishPhase()

  }

  /**
   * reset fazy
   */
  public reset(): void {

    this.ended = false

  }

}
