<template>
  <div>
    <power-stick
      :disabled="disabled"
      is-full-screen
      :is-scaled="false"
      :is-move-allowed="movementButtonsActive"
      @touchstart="touchStart"
      @position-x="changeMovementX"
      @position-y="changeMovementY"
      @end-contact="endContact"
    />
  </div>
</template>

<script lang="ts">

import { PowerStick } from '@powerplay/core-minigames-ui-ssm'
import { inputsManager } from '@powerplay/core-minigames'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { gameConfig } from '@/app/config/gameConfig'
import { MinigameVersionTypes } from '@/app/types/config'

export default defineComponent({
  components: {
    PowerStick,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
    }),
  },
  methods: {
    touchStart() {

      const touchStartInput = [MinigameVersionTypes.a, MinigameVersionTypes.c].includes(gameConfig.minigameVersionType)
      disciplinePhasesManager.phaseDraw.handleInputs(true, touchStartInput)

    },
    endContact() {

      const touchStartInput = [MinigameVersionTypes.a].includes(gameConfig.minigameVersionType)
      disciplinePhasesManager.phaseAim.handleInputs(touchStartInput)
      disciplinePhasesManager.phaseDraw.handleInputs(false, touchStartInput)

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      this.$store.commit('MovementState/SET_POSITION_X', 0)
      this.$store.commit('MovementState/SET_POSITION_Y', 0)

    },
    changeMovementX(positionX: number) {

      if (this.disabled) return

      this.$store.commit('MovementState/SET_POSITION_X', positionX)

    },
    changeMovementY(positionY: number) {

      if (this.disabled) return

      this.$store.commit('MovementState/SET_POSITION_Y', positionY)

    }

  }
})

</script>

<style lang="less">
</style>
