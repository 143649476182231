import {
  game,
  minigameConfig,
  THREE,
  gsap,
  CameraStates,
  cameraManager,
  playersManager,
  corePhasesManager,
  modes,
  type PlayerInfoForTable,
  audioManager,
  trainingManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  PlayerStates,
  BowAnimationsNames,
  ShootingTypes,
  PlayerTypes,
  AudioNames,
  AudioGroups
} from '../../types'
import { aimingDirectionManager } from '../AimPhase/AimingDirectionManager'
import {
  aimConfig,
  cameraSpecialConfig,
  gameConfig,
  shootingConfig
} from '@/app/config'
import { wind } from '@/app/entities/athlete/Wind'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { player } from '@/app/entities/athlete/player'
import { timeLimitManager } from '@/app/TimeLimitManager'
import store from '@/store'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { scoreDisplayPanel } from '@/app/entities/env/ScoreDisplayPanel'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import { StateManager } from '@/app/StateManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { opponent } from '@/app/entities/athlete/opponent/Opponent'
import { trainingTasks } from '@/app/modes/training'
import { endManager } from '@/app/EndManager'

/**
 * Trieda fazy pre vystrel
 */
export class ShootingPhase implements DisciplinePhaseManager {

  /** Ci je aktivny pohlad na sip */
  public activeLockOnArrow = false

  /* Ci sa pozerame na sip */
  public activeLookAtArrow = false

  /** Ci je aktivny pohyb po krivke */
  public activeMovingOnTrack = false

  /** Ci sme akurat v casti s emociou */
  public isInEmotion = false

  /** Ci su skipovatelne hlasky pri konecnej emocii */
  public emotionMessagesSkippable = false

  /** tween pre unlock skipu finalnej emocie */
  private tweenToSkipFinalEmotions?: gsap.core.Tween

  /** Krivka */
  private trackPath = new THREE.CurvePath<THREE.Vector3>()

  /** Dlzka krivky */
  private trackLength = 0

  /** Pozicia v % na trati pre sip */
  private arrowPositionOnTrack = 0

  /** Kolko percent je 1 meter na krivke */
  private oneMeterInPercent = 0

  /** Rychlost sipu v %/f */
  private arrowSpeed = 0

  /** Ci uz skoncila tato faza */
  private ended = false

  /** Pocet trafeni X-ka */
  private countShotsX = 0

  /** predchadzajuci zasah */
  private previousTargetPoints: THREE.Vector3[] = []

  /** index pozicie sipu do ktoreho sa zapichol aktualny, -1 ak nebol robin hood */
  private robinHoodArrowIndex = -1

  /** ci predchadzajuci vystrel bol robin hood */
  private wasPreviousRobinHood = false

  /** tween na emocii */
  private emotionTween?: gsap.core.Tween

  /** Pomocny vektor pre bezier krivku */
  private helperBezierVector = new THREE.Vector3()

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('shooting phase started')
    startPhaseStateManager.hideButtons()
    timeLimitManager.setActive(false)
    aimingDirectionManager.aimingPoint.visible = aimConfig.debug.showAimingPoint
    aimingDirectionManager.targetPoint.visible = aimConfig.debug.showTargetPoint
    aimingDirectionManager.targetPointOriginal.visible = aimConfig.debug.showTargetPointOriginal

    // vypustime sip
    this.releaseArrow()

    // this.isActive = true
    this.activeMovingOnTrack = true

  }

  /**
   * Vykreslenie pomocnych ciar krivky
   * @param pointsAll - body
   */
  private drawDebugLine(pointsAll: THREE.Vector3[]): void {

    let meshSphere
    const geometrySphere = new THREE.BoxGeometry(0.2, 0.2, 0.2)
    const materialSphere = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0x00FF00)
    })
    const groupSpheres = new THREE.Group()
    for (let i = 0; i < pointsAll.length; i++) {

      meshSphere = new THREE.Mesh( geometrySphere, materialSphere)
      meshSphere.position.x = pointsAll[i].x
      meshSphere.position.y = pointsAll[i].y
      meshSphere.position.z = pointsAll[i].z
      groupSpheres.add(meshSphere)

    }
    game.scene.add(groupSpheres)

    const geometry = new THREE.BufferGeometry().setFromPoints(pointsAll)
    const material = new THREE.LineBasicMaterial({
      color: new THREE.Color(0x00FFFF)
    })

    // Create the final object to add to the scene
    const line = new THREE.Line(geometry, material)
    game.scene.add(line)

  }

  /**
   * Vypustenie sipu
   */
  private releaseArrow(): void {

    const { bezier, speed } = shootingConfig.arrow

    if (shootingConfig.type === ShootingTypes.bar) aimingDirectionManager.calculateDeviation(true)

    // musime si aktualizovat targetPoint, ktory sa neaktualizuje kvoli narocnosti raycastu
    const point = wind.getNewPoint(aimingDirectionManager.aimingPoint.position)

    // pridame deviation, ak treba
    if (aimConfig.deviation.active) {

      const { direction, deviation } = aimingDirectionManager
      const step = aimingDirectionManager.directionStep[direction]
      point.x -= step.x * deviation
      point.y += step.y * deviation

    }

    aimingDirectionManager.moveTargetPoint(point)

    // musime si trosku upravit miesto, z ktoreho pojde sip, lebo ma inde pivot point ako kost
    player.arrow.meshFlight.position.set(
      player.arrow.mainBone.position.x,
      player.arrow.mainBone.position.y,
      player.arrow.mainBone.position.z + 0.7
    )
    player.arrow.meshFlight.rotation.set(0, 0, 0)


    this.checkRobinHood()

    this.helperBezierVector.set(
      aimingDirectionManager.targetPoint.position.x + bezier.x,
      aimingDirectionManager.targetPoint.position.y + bezier.y,
      aimingDirectionManager.targetPoint.position.z + bezier.z
    )
    const curve = new THREE.QuadraticBezierCurve3(
      player.arrow.meshFlight.position.clone(),
      this.helperBezierVector,
      aimingDirectionManager.targetPoint.position.clone()
    )

    this.trackPath.add(curve)
    this.trackLength = this.trackPath.getLength()
    this.oneMeterInPercent = 1 / this.trackLength

    // ak treba, vykreslime si krivku trate
    if (shootingConfig.debugCurve) {

      this.drawDebugLine(this.trackPath.getPoints(100))

    }

    // vypocitame si rychlost sipu
    const arrowSpeedMS = THREE.MathUtils.randFloat(speed.min, speed.max) // v m/s
    this.arrowSpeed = arrowSpeedMS / minigameConfig.fpsLimit * this.oneMeterInPercent // %/f

    // animacie
    player.setState(PlayerStates.shooting)

    // musime vymenit animacny sip za letovy sip
    player.arrow.changeArrows()

    audioManager.play(AudioNames.release)

  }

  /**
   * Vratenie dat pre body po zasahu sipom do terca
   * @returns Data
   */
  private getHitPointsData(): { points: number, countX: number, count10: number } {

    const { center: centerPoint, zone } = shootingConfig.target
    let targetPoint = aimingDirectionManager.targetPoint.position.clone()
    if (this.wasPreviousRobinHood) {

      targetPoint = this.previousTargetPoints[this.robinHoodArrowIndex].clone()

    }

    // od distance si odpocitam polomer sipu, kedze moze byt vo viacerych zonach naraz
    const distance = centerPoint.distanceTo(targetPoint) - shootingConfig.arrow.radius

    // pocitame body od 10 do 0
    let points = 10
    let countX = 0
    for (; points > 0; points -= 1) {

      // kolkokrat mame brat do uvahy ciaru a vnutro zony
      const i = Math.abs(points - 10) + 1

      // ciaru pocitame 2x pri prvej zone za 10 bodov (berie sa aj uplne stred)
      const lineCount = i === 1 ? 2 : 1

      // skontrolovanie X (stredna bodka, polka vnutornej a polka ciary)
      if (distance <= (zone.lineDiameter * 1.5) + (zone.innerDiameter * 0.5)) {

        console.log('DALI SME X')
        countX = 1

      }

      // kolko musi byt najviac vzdialenost, aby bola dana zona zasiahnuta
      const distanceSuccess = i * ((zone.lineDiameter * lineCount) + zone.innerDiameter)

      if (distance <= distanceSuccess) break

    }

    this.countShotsX += countX

    return {
      points,
      countX,
      count10: points === 10 ? 1 : 0
    }

  }

  /**
   * Pregratie komentatora pre rnak
   */
  private playAudioCommentatorRank(): string {

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return ''

    const rank = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentatorRank6

    if (rank === 1) {

      audio = AudioNames.commentatorRank1

    } else if (rank <= 3) {

      audio = AudioNames.commentatorRank23

    } else if (rank <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentatorRank45

    }

    // pri boss fighte a 2. mieste davame speci hlasku
    if (modes.isEventBossFight() && rank > 1) audio = AudioNames.commentatorRank6

    return audio

  }

  /**
   * Prehratie zvukov po trafeni terca
   * @param points - Pocet trafenych bodov
   */
  private playAudioOnHitTarget(points: number): void {

    audioManager.play(AudioNames.announcerPrefix + points)
    let commentatorAudioName = ''

    // pri poslendom pokuse uz zaciname hrat hyped
    if (corePhasesManager.disciplineActualAttempt === gameConfig.numberOfAttempts) {

      commentatorAudioName = this.playAudioCommentatorRank()

    } else {

      commentatorAudioName = AudioNames.commentatorPoints8
      if (points === 10) commentatorAudioName = AudioNames.commentatorPoints10
      if (points === 9) commentatorAudioName = AudioNames.commentatorPoints9
      if (this.wasPreviousRobinHood) commentatorAudioName = AudioNames.commentatorRobinHood

    }

    if (points === 10) audioManager.play(AudioNames.cheerClap)
    if (points === 9) audioManager.play(AudioNames.clap)


    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        if (commentatorAudioName !== '' && !audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

          audioManager.play(commentatorAudioName, undefined, undefined, undefined, true)

        }

      }
    })

  }

  /**
   * Vypocitanie bodov
   */
  private calculatePoints(): void {

    const pointsData = this.getHitPointsData()
    console.log(`trafenych ${pointsData.points} bodov`)

    // zapiseme vysledok
    playersManager.setPlayerResults(pointsData.points, pointsData.count10, pointsData.countX)
    playersManager.setStandings()
    endManager.resultsCount += 1

    const playerScore = Number(playersManager.getStandings().find((standing: PlayerInfoForTable) => {

      return standing.playable

    })?.result) || 0
    store.commit('TutorialState/SET_RESULT_POINTS', playerScore)

    // zaznacenie bodov do terca za hraca
    const pointsSoFar = playersManager.getCountedResultsMainSoFar(playersManager.getPlayer().uuid)
    scoreDisplayPanel.setNumberOnPanel(pointsSoFar, PlayerTypes.player)
    if (opponent.uuid !== '') {

      const pointsSoFarOpponent = playersManager.getCountedResultsMainSoFar(opponent.uuid)
      scoreDisplayPanel.setNumberOnPanel(pointsSoFarOpponent, PlayerTypes.opponent)

    }

    worldEnv.playTargetMarker(pointsData.countX === 1 ? 11 : pointsData.points)

    let firstLineTextType = 0
    let firstLineText = ''

    if (pointsData.countX === 1) {

      firstLineText = 'text-trap-sprite'
      firstLineTextType = THREE.MathUtils.randInt(1, 4)

    } else if (pointsData.points === 10) {

      firstLineText = 'text-trap-sprite'
      firstLineTextType = THREE.MathUtils.randInt(5, 7)

    }
    if (this.wasPreviousRobinHood) {

      firstLineTextType = 0
      firstLineText = 'text-robin-hood'

    }

    const showFirstLine = firstLineText.length > 0
    const showSecondLine = true
    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)

    store.commit('TextMessageState/SET_STATE', {
      showFirstLine: showFirstLine,
      showSecondLine: showSecondLine,
      firstLineText,
      firstLineTextType: firstLineTextType,
      secondLineTextType: 0,
      secondLineLeftNumber: pointsData.countX === 1 ? 11 : pointsData.points,
      showMessage: true,
      showType: showFirstLine ? 2 : 3
    })

    trainingTasks.countArrowPoints(pointsData)
    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        this.playAudioOnHitTarget(pointsData.points)

      }
    })

    gsap.to({}, {
      onComplete: () => {

        store.commit(
          'MiniTableState/SET_STATE',
          {
            rowsData: StateManager.getDataForMiniTable(pointsData.points),
            show: corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency !== 0 &&
            !modes.isTutorial() && !modes.isTrainingMode()
          }
        )

      },
      duration: 1
    })


  }

  /**
   * Nastavenie timeru na skip final emocie
   */
  private setTimerToSkipFinalEmotions(): void {

    // dame timer na skip poslednej emocie s hlaskami
    this.tweenToSkipFinalEmotions = gsap.to({}, {
      duration: 1.5,
      onComplete: () => {

        this.emotionMessagesSkippable = true

      }
    })

  }

  /**
   * nastavime emocne message
   * @returns True, ak zobrazujeme emocnu hlasku
   */
  private showEmotionMessages(): boolean {

    const points = playersManager.getCountedResultsMainSoFar(playersManager.getPlayer().uuid)
    const personalBest = playersManager.getPlayer().personalBest
    const position = playersManager.getPlayerActualPosition(player.uuid)

    const inTop3 = position <= 3
    const isPersonalBest = points >= personalBest

    // ak mame nejaku hlasku/y, tak zobrazime kameru kusok inak
    if ((modes.isDailyLeague() && !playersManager.isPlayerImproved()) || (!inTop3 && !isPersonalBest)) return false

    startPhaseStateManager.hideAllTextMessages()

    let pbText = ''
    if (isPersonalBest) {

      pbText = points > personalBest ? 'newPersonalBest' : 'personalBest'

    }

    store.commit('EmotionMessagesState/SET_STATE', {
      showMessage: true,
      firstLineText: inTop3 ? 'congratulations' : '',
      firstLineTextSecond: pbText,
      secondLineText: inTop3 ? position : 0,
      secondLineTextSecond: isPersonalBest ? String(points) : '',
    })

    // dame timer na skip poslednej emocie s hlaskami
    this.setTimerToSkipFinalEmotions()

    return true

  }

  /**
   * Spravenie emocie po vystrele
   */
  private makeEmotion(): void {

    this.activeLockOnArrow = false

    player.setHandsVisibility(false)
    player.setHairVisibility(true)
    // game.getMesh('envDynamic_ArcheryTargetsFar').visible = true
    player.bow.animationsManager.changeTo(BowAnimationsNames.idle)

    player.setGameCameraSettings(1, true)
    cameraManager.setState(CameraStates.discipline)
    cameraManager.changeRenderSettings(undefined, undefined, 73.7 - 35)
    worldEnv.setVisibilityShadowsOnTarget(false)
    worldEnv.changeTextureDigitalDisplay(true)

    if (
      corePhasesManager.disciplineActualAttempt > 1 &&
      corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency === 0
    ) {

      if (
        modes.isTrainingMode() &&
        corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount
      ) {

        this.finishPhase()
        return

      }

      this.isInEmotion = true
      player.changeCameraSettings(
        gameConfig.cameraEmotionConfig.idealOffset,
        gameConfig.cameraEmotionConfig.idealLookAt,
        gameConfig.cameraEmotionConfig.coefSize,
        gameConfig.cameraEmotionConfig.changeLerp,
        true,
      )
      player.setState(PlayerStates.emotion)

      if (corePhasesManager.disciplineActualAttempt === corePhasesManager.provisionalResultsFrequency * 2) {

        audioManager.play(AudioNames.audienceHyped)

        store.commit('UiState/SET_STATE', {
          showTimeKeeper: false,
          showTrainingLayout: modes.isTrainingMode(),
          isTraining: modes.isTrainingMode()
        })

      }

      // startPhaseStateManager.hideTextMessage()

      let withEmotionMessages = false

      // UI pri emocii, ak islo o posledny pokus
      const lastAttempt = corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount
      if (lastAttempt) {

        if (modes.isTrainingMode()) {

          store.commit('TrainingState/HIDE_LEFT_BOXES')

          // HIGH SCORE - TRENING
          store.commit('TrainingState/SET_HIGH_SCORE', {
            newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
            showNewHighScore: trainingManager.isNewHighScore()
          })

          this.setTimerToSkipFinalEmotions()

        } else {

          // UI veci mimo TG
          withEmotionMessages = this.showEmotionMessages()


        }

      }

      if (withEmotionMessages) return

      const waitInEmotion = shootingConfig.waitInEmotion

      this.emotionTween = gsap.to(
        {},
        {
          duration: waitInEmotion,
          onComplete: () => {

            this.tweenToSkipFinalEmotions?.kill()
            this.afterEmotions()

          }
        }
      )

    } else {

      this.finishPhase()

    }

  }

  /**
   * Spravenie veci po emociach
   */
  public afterEmotions(): void {

    store.commit('TrainingState/SET_HIGH_SCORE', {
      showNewHighScore: false
    })
    store.commit('EmotionMessagesState/SET_STATE', {
      showMessage: false
    })

    this.isInEmotion = false
    cameraManager.changeIdeals(
      gameConfig.cameraConfig.idealOffset,
      gameConfig.cameraConfig.idealLookAt,
      gameConfig.cameraConfig.coefSize,
      gameConfig.cameraConfig.changeLerp,
      true
    )
    this.finishPhase()

  }

  /**
   * Vyhodnotenie robin hood
   */
  private checkRobinHood(): void {

    const { chance } = shootingConfig.robinHood
    const targetPoint = aimingDirectionManager.targetPoint.position.clone()
    this.robinHoodArrowIndex = this.getRobinHoodTargetIndex(targetPoint)

    if (
      this.previousTargetPoints.length === 0 ||
      this.robinHoodArrowIndex < 0
    ) {

      console.log('sip bol daleko na robin hood')
      this.previousTargetPoints.push(targetPoint.clone())
      return

    }
    console.log('ROBIN HOOD!')

    const chanceRandom = Math.random()

    console.log(`hod kockou: ${chanceRandom}, sanca: ${chance}`)

    if (this.wasPreviousRobinHood || chanceRandom > chance) {

      console.log('sip sa nezapichol')
      this.wasPreviousRobinHood = false
      this.deviateArrow()

      return

    }

    console.log(`sip sa zapichol do sipu ${this.robinHoodArrowIndex + 1}`)
    this.wasPreviousRobinHood = true

    aimingDirectionManager.targetPoint.position.set(
      this.previousTargetPoints[this.robinHoodArrowIndex].x,
      this.previousTargetPoints[this.robinHoodArrowIndex].y + shootingConfig.arrow.yOffsetArrowHeel,
      aimingDirectionManager.targetPoint.position.z - shootingConfig.arrow.arrowLength
    )

    this.previousTargetPoints.push(targetPoint.clone())

  }

  /**
   * Vrati index previousTargetPoints pozicie sipu, ktory sme zasiahli
   * @param targetPoint - bod v terci kde sme zasiahli
   * @returns - index previousTargetPoint
   */
  private getRobinHoodTargetIndex(targetPoint: THREE.Vector3): number {

    return this.previousTargetPoints.findIndex((previousTargetPoint) => {

      return previousTargetPoint.distanceTo(targetPoint) <= shootingConfig.robinHood.distance

    })

  }

  /**
   * Vychylenie od sipu ku ktoremu som strelil, opakuje sa, ak by sa malo vychylit prilis blizko k inemu sipu
   */
  private deviateArrow(): void {

    console.log(`vychylujem od sipu ${this.robinHoodArrowIndex + 1}`)

    const targetPoint = this.previousTargetPoints[this.robinHoodArrowIndex].clone()
    const direction = THREE.MathUtils.randInt(0, 23) // lebo mame 24 smerov

    const step = aimingDirectionManager.directionStep[direction]
    aimingDirectionManager.targetPoint.position.x = targetPoint.x - step.x * shootingConfig.robinHood.deviation
    aimingDirectionManager.targetPoint.position.y = targetPoint.y + step.y * shootingConfig.robinHood.deviation
    if (this.getRobinHoodTargetIndex(aimingDirectionManager.targetPoint.position) >= 0) {

      this.deviateArrow()

    }

  }

  /**
   * Kontrola a riesenie inputov
   */
  public handleInputs(): void {

    // skip na emocie
    if (this.emotionMessagesSkippable) {

      this.emotionTween?.kill()
      this.emotionMessagesSkippable = false
      this.afterEmotions()

      return

    }

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    // let sipu
    if (this.activeMovingOnTrack) {

      this.arrowPositionOnTrack += this.arrowSpeed

      // ked sme presli uz nejaku cast, tak dame lock na sip a letim v jeho pohlade
      if (this.arrowPositionOnTrack > 0.5 && !this.activeLockOnArrow) {

        // this.activeLookAtArrow = true
        this.activeLockOnArrow = true
        disciplinePhasesManager.phaseAim.resetCameraAndWrapper()
        player.changeCameraSettings(
          cameraSpecialConfig.cameraArrow.idealOffset,
          cameraSpecialConfig.cameraArrow.idealLookAt,
          cameraSpecialConfig.cameraArrow.coefSize,
          cameraSpecialConfig.cameraArrow.changeLerp
        )

        worldEnv.setVisibilityShadowsOnTarget(true)
        // game.getMesh('envDynamic_ArcheryTargetsFar').visible = false
        console.log('VISIBLE FALAAASSSEEE')

      }

      if (this.arrowPositionOnTrack >= 1) {

        this.arrowPositionOnTrack = 1
        this.activeMovingOnTrack = false

        // vyhodnotime body
        this.calculatePoints()
        store.commit(
          'WindState/SET_SHOW_WIND',
          false
        )
        store.commit(
          'WindState/SET_SHOW_COUNTDOWN',
          false
        )

        audioManager.play(this.wasPreviousRobinHood ? AudioNames.robinHood : AudioNames.hit)
        this.activeLookAtArrow = true

        const cameraOffsetEdited = cameraSpecialConfig.cameraArrowInTarget.idealOffset
        const cameraOffsetTransition = cameraSpecialConfig.cameraArrow.idealOffset.clone()
        const cameraTransitionDuration = cameraSpecialConfig.cameraArrowInTarget.duration

        // koniec, pockame este chvilku
        gsap.to(
          cameraOffsetTransition,
          {
            duration: cameraTransitionDuration,
            x: cameraOffsetEdited.x,
            z: cameraOffsetEdited.z,
            y: cameraOffsetEdited.y,
            onUpdate: () => {

              cameraManager.changeIdeals(cameraOffsetTransition)

            },
            onComplete: () => {

              this.activeLookAtArrow = false
              worldEnv.stopTargetMarkers()
              this.makeEmotion()
              tutorialFlow.arrowInTarget()

            }
          }
        )


      }

      // nastavenie pozicie a rotacie
      const position = this.trackPath.getPointAt(this.arrowPositionOnTrack)
      player.arrow.setFlightPosition(position)
      let nextPercent = this.arrowPositionOnTrack + 0.01
      if (nextPercent > 1) {

        nextPercent = 1

      } else {

        player.arrow.setFlightRotation(this.trackPath.getPointAt(nextPercent))

      }

    }

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return

    this.emotionMessagesSkippable = false

    // startPhaseStateManager.hideTextMessage()
    this.ended = true
    this.activeLockOnArrow = false
    console.warn('shooting phase ended')
    this.callbackEnd()

    // vymenime texturu digital numbers


  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.activeLockOnArrow = false
    this.activeMovingOnTrack = false
    this.trackPath = new THREE.CurvePath<THREE.Vector3>()
    this.trackLength = 0
    this.arrowPositionOnTrack = 0
    this.oneMeterInPercent = 0
    this.arrowSpeed = 0
    this.ended = false
    this.countShotsX = 0
    this.robinHoodArrowIndex = -1

    // musime vymenit letovy sip naspat na animacny sip
    player.arrow.keepArrowOnTarget()
    player.arrow.changeArrows(false)

    if ((corePhasesManager.disciplineActualAttempt) % corePhasesManager.provisionalResultsFrequency === 0) {

      this.previousTargetPoints = []

    }

    this.emotionMessagesSkippable = false
    this.wasPreviousRobinHood = false
    this.emotionTween?.kill()

  }

}
