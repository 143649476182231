/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteNotLooped = 'notLooped',
  spriteAnnouncer = 'announcer',
  spriteClaps = 'claps',

  audienceHyped = 'audience-hyped4',
  audienceQuiet = 'audience-noise',

  announcerPrefix = 'archery_announcer_',

  hit = 'archery_hit_sound_var01',
  hit2 = 'archery_hit_sound_var012',

  release = 'archery_release_sound_var01',
  release2 = 'archery_release_sound_var012',

  robinHood = 'archery_robin_hood_sound',

  clap = 'only_clap',
  cheerClap = 'cheer_and_clap',

  commentatorIntro = 'VO_archery_intro_var01',
  commentatorIntro2 = 'VO_archery_intro_var012',
  commentatorBefore1stShot = 'VO_archery_before_1st_shot_var01',
  commentatorBefore1stShot2 = 'VO_archery_before_1st_shot_var012',
  commentatorProvisionalStandings = 'VO_archery_provisional_standings_var01',
  commentatorProvisionalStandings2 = 'VO_archery_provisional_standings_var012',
  commentatorFinalResults = 'VO_archery_final_results_var01',
  commentatorFinalResults2 = 'VO_archery_final_results_var012',
  commentatorPoints8 = 'VO_archery_8_points_var01',
  commentatorPoints8v2 = 'VO_archery_8_points_var012',
  commentatorPoints9 = 'VO_archery_9_points_var01',
  commentatorPoints9v2 = 'VO_archery_9_points_var012',
  commentatorPoints10 = 'VO_archery_10_points_var01',
  commentatorPoints10v2 = 'VO_archery_10_points_var012',
  commentatorRobinHood = 'VO_archery_robin_hood',
  commentatorRank1 = 'VO_archery_rank_1',
  commentatorRank23 = 'VO_archery_rank_2_3',
  commentatorRank45 = 'VO_archery_rank_4_5',
  commentatorRank6 = 'VO_archery_rank_6'

}

/** Kategorie zvukov */
export enum AudioCategories {

  audience = 'audience',
  misc = 'misc',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators',
  audience = 'audience'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumePlaceTypes {

  start = 'start',
  stop = 'stop'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumeTypes {

  increase = 'increase',
  decrease = 'decrease'

}

/** Typy sektorov pre hlasitost */
export enum AudienceVolumeSectorTypes {

  full = 'full',
  graduallyDecrease = 'graduallyDecrease',
  graduallyIncrease = 'graduallyIncrease',
  min = 'min'

}
