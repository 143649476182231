/**
 * Specialny konfig pre obrazovku zo skore
 */
export const scoreDisplayPanelConfig = {

  /** O kolko sa ma na z osi posunut kazdy mesh */
  meshesShiftZ: -0.01,

  /** Pocet prvkov na texture horizontalne ci vertikalne */
  digitsInTextureOneDir: 4,

}