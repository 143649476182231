import type { THREE } from '@powerplay/core-minigames'

/** Strany */
export enum Sides {
  LEFT = 'Left',
  RIGHT = 'Right'
}

/** Specialny vektor na osiach X a Z */
export interface VectorXZ {
  x: number,
  z: number
}

/*
 * export type PositionTupleValue = 1 | -1
 * export type PosX = PositionTupleValue
 * export type PosY = PositionTupleValue
 * export type PositionTuple = [PosX, PosY]
 */
export type PositionTuple = [number, number]

/** Info ohladom priesecnika hraca a objektu */
export interface IntersectionInfo {

  normal: THREE.Vector3,
  distance: number,
  point: THREE.Vector3

}

/** Stavy hraca, hlavne pre animacie */
export enum PlayerStates {

  idle = 'idle',
  prepare = 'prepare',
  idle2 = 'idle2',
  loading = 'loading',
  drawing = 'drawing',
  shooting = 'shooting',
  emotion = 'emotion'

}

/** Typy hraca */
export enum PlayerTypes {

  player = 'Player1',
  opponent = 'Player2'

}

